import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Logo from '../common/Logo';
import { navItems } from '../../config/navigation';

const Sidebar = () => {
  const { hotelId } = useParams<{ hotelId: string }>();
  
  return (
    <div className="w-64 bg-white border-r border-gray-200 flex-shrink-0">
      <div className="h-full flex flex-col">
        <div className="p-6">
          <Logo className="h-8" />
        </div>
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <nav className="mt-5 flex-1 px-2 space-y-1">
            {navItems.map((item) => {
              const Icon = item.icon;
              const fullPath = `/dashboard/${hotelId}${item.path ? `/${item.path}` : ''}`;
              
              return (
                <NavLink
                  key={item.name}
                  to={fullPath}
                  className={({ isActive }) =>
                    `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                      isActive
                        ? 'bg-primary text-white'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`
                  }
                >
                  <Icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  {item.name}
                </NavLink>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar; 