import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { clearAuthData } from '../utils/auth';

interface AuthContextType {
  token: string | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  error: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [error, setError] = useState<string | null>(null);

  const login = async (email: string, password: string) => {
    try {
      console.log('Login attempt:', { email });
      setError(null);
      
      const response = await api.post('/api/auth/login', { 
        email, 
        password 
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          throw new Error('Connection timeout - please try again');
        }
        throw error;
      });

      console.log('Login response:', {
        status: response.status,
        success: response.data.success,
        hasToken: !!response.data.token,
        userData: response.data.user
      });
      
      if (response.data?.token) {
        // Store auth data
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        
        // Update state
        setToken(response.data.token);
        setIsAuthenticated(true);
        
        // Update API headers
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        console.log('Login successful, navigating to dashboard...');
        
        // Navigate to dashboard
        if (response.data.user?.hotel_id) {
          navigate(`/dashboard/${response.data.user.hotel_id}`);
        } else {
          console.warn('No hotel_id in user data');
          navigate('/dashboard');
        }
      } else {
        throw new Error('No token received from server');
      }
    } catch (error: any) {
      console.error('Login error:', {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        status: error.response?.status
      });
      
      let errorMessage = 'Login failed';
      if (error.code === 'ECONNABORTED') {
        errorMessage = 'Connection timeout - please try again';
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      setError(errorMessage);
      setToken(null);
      setIsAuthenticated(false);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      throw error;
    }
  };

  const logout = () => {
    console.log('Logging out...');
    clearAuthData();
    setToken(null);
    setIsAuthenticated(false);
    navigate('/');
  };

  // Check auth status on mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    
    console.log('Checking auth state:', {
      hasToken: !!storedToken,
      hasUser: !!storedUser
    });

    if (storedToken && storedUser) {
      try {
        const userData = JSON.parse(storedUser);
        setToken(storedToken);
        setIsAuthenticated(true);
        api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      } catch (error) {
        console.error('Error parsing stored user data:', error);
        clearAuthData();
      }
    } else {
      setToken(null);
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      token,
      isAuthenticated,
      login,
      logout,
      error
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider; 