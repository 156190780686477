import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import AuthProvider, { useAuth } from './contexts/AuthContext';
import { HotelProvider } from './contexts/HotelContext';
import Overview from './components/dashboard/Overview';
import Bookings from './components/dashboard/Bookings';
import MarketData from './components/dashboard/MarketData';
import Revenue from './components/dashboard/Revenue';
import GuestCRM from '../app/dashboard/guests/page';
import TaxiServices from './pages/TaxiServices';
import ReviewsComponent from './components/dashboard/Reviews';
import ZanzibarLandingPage from './pages/ZanzibarLandingPage';
import NewYearLandingPage from './pages/NewYearLandingPage';
import StandardLandingPage from './pages/StandardLandingPage';
import RwandaLandingPage from './pages/RwandaLandingPage';

// Loading spinner component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
  </div>
);

// Protected route wrapper
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  console.log('Route Check:', { 
    isAuthenticated, 
    path: location.pathname,
    hasToken: !!localStorage.getItem('token')
  });

  if (!isAuthenticated) {
    // Store the attempted URL
    localStorage.setItem('intendedPath', location.pathname);
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

// Public route wrapper - redirects to dashboard if authenticated
const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  console.log('Public Route Check:', { 
    isAuthenticated, 
    path: location.pathname 
  });

  // If authenticated and on landing page, redirect to last known dashboard
  if (isAuthenticated && location.pathname === '/') {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { hotel_id } = JSON.parse(userData);
      if (hotel_id) {
        return <Navigate to={`/dashboard/${hotel_id}`} replace />;
      }
    }
  }

  return <>{children}</>;
};

function App() {
  return (
    <AuthProvider>
      <HotelProvider>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Public route - Landing Page */}
            <Route 
              path="/" 
              element={
                <PublicRoute>
                  <LandingPage />
                </PublicRoute>
              } 
            />
            
            {/* Redirect /dashboard to / */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Navigate to="/" replace />
                </ProtectedRoute>
              } 
            />
            
            {/* Protected Dashboard routes */}
            <Route
              path="/dashboard/:hotelId"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            >
              <Route index element={<Overview />} />
              <Route path="bookings" element={<Bookings />} />
              <Route path="market-data" element={<MarketData />} />
              <Route path="transactions" element={<Revenue />} />
              <Route path="guests" element={<GuestCRM />} />
              <Route path="taxi" element={<TaxiServices />} />
              <Route path="reviews" element={<ReviewsComponent />} />
              {/* ... other dashboard routes ... */}
            </Route>

            <Route path="/zanzibar" element={<ZanzibarLandingPage />} />
            <Route path="/new-year" element={<NewYearLandingPage />} />
            <Route path="/standard" element={<StandardLandingPage />} />
            <Route path="/rwanda" element={<RwandaLandingPage />} />

            {/* Catch all - redirect to landing */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </HotelProvider>
    </AuthProvider>
  );
}

export default App;
