import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import AuthProvider from './contexts/AuthContext';
import App from './App';
import './index.css';

// Add router error logger with better error handling
const RouterErrorLogger = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    try {
      console.log('=== Router State ===');
      console.log('Current location:', {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
        state: location.state,
        token: localStorage.getItem('token') ? 'exists' : 'none'
      });
    } catch (error) {
      console.error('Router logger error:', error);
    }
  }, [location]);

  return <>{children}</>;
};

// Enhanced error boundary with React runtime error handling
class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; error: Error | null; errorInfo: React.ErrorInfo | null }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null, 
      errorInfo: null 
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.error('=== React Runtime Error ===');
    console.error('Error:', error);
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to your error reporting service
    console.error('=== Detailed Error Info ===');
    console.error('Error:', {
      name: error.name,
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack
    });

    // Try to recover from the error
    try {
      localStorage.removeItem('token');
      window.location.href = '/';
    } catch (e) {
      console.error('Error recovery failed:', e);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h1 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h1>
            <div className="bg-red-50 p-4 rounded mb-4">
              <p className="text-red-700">{this.state.error?.message}</p>
            </div>
            <button
              onClick={() => {
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
              className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-primary-600"
            >
              Return Home
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Wrap the app with error handling
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <RouterErrorLogger>
          <AuthProvider>
            <App />
          </AuthProvider>
        </RouterErrorLogger>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
