import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../components/common/Logo';
import LoginModal from '../components/auth/LoginModal';
import { RegisterModal } from '../components/auth/RegisterModal';

const StandardLandingPage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [selectedStory, setSelectedStory] = useState<any>(null);

  return (
    <>
      <Helmet>
        <title>Boost Your Hotel Revenue | HotelOnline</title>
        {/* Keep same meta tags but remove seasonal content */}
      </Helmet>

      <div className="min-h-screen">
        <div className="relative bg-gradient-to-br from-secondary to-primary overflow-hidden">
          <div className="container mx-auto px-6 py-24 text-center">
            <h1 className="text-6xl font-bold text-white mb-8 leading-tight">
              Boost Your Hotel Revenue by <br />
              <span className="text-primary-200">25% or More</span>
            </h1>
            
            {/* Rest of the content without any seasonal theming */}
          </div>
        </div>

        {/* Keep other sections with standard messaging */}
      </div>
    </>
  );
};

export default StandardLandingPage; 