import { MarketData } from '../types/market';
import { Transaction } from '../types/wallet';
import api, { API_BASE_URL } from '../api';

export { API_BASE_URL };

export const fetchTransactions = async (hotelId: number): Promise<Transaction[]> => {
  try {
    const response = await api.get(`/api/transactions`, {
      params: {
        hotel_id: hotelId
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // Log the response for debugging
    console.log('Raw transactions response:', {
      status: response.status,
      url: response.config.url,
      params: response.config.params,
      data: response.data
    });

    // Check if response is valid JSON
    if (typeof response.data === 'string') {
      console.error('Received HTML instead of JSON:', response.data.substring(0, 100));
      return [];
    }

    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error fetching transactions:', error);
    return [];
  }
};

export async function fetchMarketData(hotelId: number): Promise<MarketData[]> {
  console.log('API: Fetching market data for hotel:', hotelId);
  
  try {
    const response = await api.get(`/api/market-data`, {
      params: {
        hotel_id: hotelId
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    
    console.log('Raw API response:', {
      status: response.status,
      url: response.config.url,
      params: response.config.params,
      data: response.data
    });
    
    if (typeof response.data === 'string') {
      console.error('Received HTML instead of JSON:', response.data.substring(0, 100));
      return [];
    }
    
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('API: Error fetching market data:', error);
    throw error;
  }
}
