import { LayoutDashboard, BarChart, Calendar, Star, DollarSign, Share2, Building, Database, Users, Car } from 'lucide-react';

export const navItems = [
  {
    name: 'Overview',
    path: '', // empty for index route
    icon: LayoutDashboard
  },
  {
    name: 'Market Data',
    path: 'market-data',
    icon: BarChart
  },
  {
    name: 'Bookings',
    path: 'bookings',
    icon: Calendar
  },
  { name: 'Reviews', icon: Star, path: 'reviews' },
  { name: 'Transactions', icon: DollarSign, path: 'transactions' },
  { name: 'Distribution', icon: Share2, path: 'distribution' },
  { name: 'Guest CRM', icon: Users, path: 'guests' },
  { name: 'Hotel Profile', icon: Building, path: 'profile' },
  { name: 'Technology Stack', icon: Database, path: 'tech' },
  { name: 'Taxi Services', icon: Car, path: 'taxi' }
]; 