import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { 
  ArrowUpDown, Download, DollarSign, Users, 
  TrendingUp, AlertCircle, RefreshCw, ArrowRight, CheckCircle
} from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { DistributionSignupModal } from '../modals/DistributionSignupModal';

interface Booking {
  BookingID: string;
  GuestName: string;
  StartDate: string;
  EndDate: string;
  RoomTypeName: string;
  TotalAmount: number;
  CurrentStatus: string;
  Source: string;
  CreateDateTime: string;
  CurrencyCode: string;
  RoomNo: string;
  adults: number;
  children: number;
  Mobile: string;
  Email: string;
  Location: string;
  GuestCount: number;
  NetRevenue: number;
  PaymentStatus: string;
  TotalExtraCharge: number;
  TotalDiscount: number;
  TotalTax: number;
  PackageName: string;
  source_channel: string;
}

interface PaginationData {
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
  hasMore: boolean;
}

interface BookingStats {
  totalBookings: number;
  totalRevenue: number;
  uniqueGuests: number;
  averageBookingValue: number;
  confirmedBookings: number;
  cancelledBookings: number;
  cancellationRate: number;
}

interface FilterOptions {
  sourcesCount: number;
  roomTypesCount: number;
  statusCount: number;
  minAmount: number;
  maxAmount: number;
}

interface StatCardProps {
  title: string;
  value: string;
  icon: React.ElementType;
  trend?: number;
  color?: string;
}

interface CallToAction {
  type: string;
  title: string;
  message: string;
  benefits: string[];
  stats: {
    averageRevenueIncrease: string;
    averageCommissionSavings: string;
    happyClients: string;
  };
  action: {
    text: string;
    modalType: string;
  };
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon: Icon, trend, color = "blue" }) => (
  <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <p className="text-2xl font-bold mt-2">{value}</p>
        {trend !== undefined && (
          <p className={`text-sm mt-2 ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            {trend >= 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </p>
        )}
      </div>
      <div className={`p-4 bg-${color}-50 rounded-full`}>
        <Icon className={`w-6 h-6 text-${color}-500`} />
      </div>
    </div>
  </div>
);

const Bookings = () => {
  const { hotelId } = useParams<{ hotelId: string }>();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const [stats, setStats] = useState<BookingStats | null>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [callToAction, setCallToAction] = useState<CallToAction | null>(null);

  // Initialize filters with empty strings instead of undefined
  const [filters, setFilters] = useState({
    status: '',
    source: '',
    dateFrom: undefined as Date | undefined,
    dateTo: undefined as Date | undefined,
    dateType: 'check_in' as 'check_in' | 'check_out' | 'booking_date',
    search: '',
    roomType: ''
  });

  const [sortConfig, setSortConfig] = useState({
    sortBy: 'CreateDateTime',
    sortOrder: 'desc'
  });
  const [page, setPage] = useState(1);

  const fetchBookings = useCallback(async () => {
    try {
      if (!bookings.length) {
        setLoading(true);
      } else {
        setIsRefetching(true);
      }
      setError(null);
      
      // Only add parameters that have values
      const params: Record<string, string> = {
        page: page.toString(),
        pageSize: '20',
        sortBy: sortConfig.sortBy,
        sortOrder: sortConfig.sortOrder
      };

      if (filters.status) params.status = filters.status;
      if (filters.source) params.source = filters.source;
      if (filters.search) params.search = filters.search;
      if (filters.roomType) params.roomType = filters.roomType;
      
      if (filters.dateFrom) {
        params.dateFrom = format(filters.dateFrom, 'yyyy-MM-dd');
        params.dateType = filters.dateType;
      }
      
      if (filters.dateTo) {
        params.dateTo = format(filters.dateTo, 'yyyy-MM-dd');
        params.dateType = filters.dateType;
      }

      const queryParams = new URLSearchParams(params);
      const response = await api.get(`/api/bookings/${hotelId}?${queryParams}`);
      
      if (response.data) {
        // Only use sample data if there are no real bookings
        if (response.data.stats.totalBookings === 0) {
          setBookings(response.data.bookings || []);
        } else {
          // Filter out any sample bookings when we have real ones
          const realBookings = response.data.bookings.filter((b: Booking) => !b.BookingID.startsWith('SAMPLE-'));
          setBookings(realBookings);
        }
        setPagination(response.data.pagination);
        setStats(response.data.stats);
        setCallToAction(response.data.callToAction);
      }
    } catch (error: any) {
      console.error('Failed to fetch bookings:', error);
      
      if (error.response?.status === 401) {
        return;
      }
      
      setError(error.response?.data?.error || 'Failed to load bookings');
    } finally {
      setLoading(false);
      setIsRefetching(false);
    }
  }, [hotelId, page, sortConfig, filters, bookings.length]);

  // Reset filters function
  const handleResetFilters = () => {
    setFilters({
      status: '',
      source: '',
      dateFrom: undefined,
      dateTo: undefined,
      dateType: 'check_in',
      search: '',
      roomType: ''
    });
  };

  // Call fetchBookings after filters are reset
  useEffect(() => {
    if (hotelId) {
      fetchBookings();
    }
  }, [hotelId, fetchBookings]);

  const handleSort = (key: string) => {
    setSortConfig(prev => ({
      sortBy: key,
      sortOrder: prev.sortBy === key && prev.sortOrder === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleExport = async () => {
    // Implement CSV export functionality
    const csvContent = [
      // Headers
      ['Booking ID', 'Guest Name', 'Check In', 'Check Out', 'Room Type', 'Amount', 'Status', 'Source'],
      // Data
      ...bookings.map(booking => [
        booking.BookingID,
        booking.GuestName,
        new Date(booking.StartDate).toLocaleDateString(),
        new Date(booking.EndDate).toLocaleDateString(),
        booking.RoomTypeName,
        booking.TotalAmount,
        booking.CurrentStatus,
        booking.Source
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `bookings-${new Date().toISOString()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const filteredBookings = useMemo(() => bookings, [bookings]);

  // Add auth check on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
  }, []);

  if (loading && !bookings.length) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg shadow-lg">
        <div className="flex items-center">
          <div className="py-1">
            <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
          </div>
          <div>
            <p className="font-bold">Error</p>
            <p className="text-sm">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Statistics Cards */}
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ${isRefetching ? 'opacity-50' : ''}`}>
        {stats && (
          <>
            <StatCard
              title="Total Bookings"
              value={stats.totalBookings.toString()}
              icon={Users}
              color="blue"
              trend={12}
            />
            <StatCard
              title="Total Revenue"
              value={stats.totalRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              icon={DollarSign}
              color="green"
              trend={8}
            />
            <StatCard
              title="Average Booking Value"
              value={stats.averageBookingValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              icon={TrendingUp}
              color="purple"
              trend={-3}
            />
            <StatCard
              title="Cancellation Rate"
              value={`${stats.cancellationRate}%`}
              icon={AlertCircle}
              color="red"
              trend={5}
            />
          </>
        )}
      </div>

      {/* Simple Call to Action Banner - Show only when there are no actual bookings */}
      {(!loading && stats?.totalBookings === 0) && (
        <div className="bg-gradient-to-r from-primary to-secondary text-white rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h2 className="text-xl font-semibold">Start Getting Direct Bookings</h2>
              <p className="text-white/90">Connect your property to leading channels and start receiving bookings</p>
            </div>
            <button
              onClick={() => setShowDistributionModal(true)}
              className="flex items-center space-x-2 bg-white text-primary px-6 py-2.5 rounded-lg hover:bg-primary-50 transition-all"
            >
              <span>Get Started</span>
              <ArrowRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      {/* Filters */}
      <div className={`bg-white rounded-lg shadow-sm p-4 border border-gray-100 ${isRefetching ? 'opacity-50' : ''}`}>
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="flex flex-wrap gap-4 items-center">
            <input
              type="text"
              placeholder="Search bookings..."
              className="px-4 py-2 border rounded-lg"
              value={filters.search}
              onChange={e => setFilters(prev => ({ ...prev, search: e.target.value }))}
            />
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <DatePicker
                  selectsRange
                  startDate={filters.dateFrom}
                  endDate={filters.dateTo}
                  onChange={(dates: [Date | null, Date | null]) => setFilters(prev => ({
                    ...prev,
                    dateFrom: dates[0] || undefined,
                    dateTo: dates[1] || undefined
                  }))}
                  dateFormat="dd/MM/yyyy"
                  className="px-4 py-2 border rounded-lg"
                  placeholderText="Select date range"
                  isClearable
                />
                <select
                  className="px-4 py-2 border rounded-lg"
                  value={filters.dateType}
                  onChange={e => setFilters(prev => ({ 
                    ...prev, 
                    dateType: e.target.value as typeof filters.dateType 
                  }))}
                >
                  <option value="check_in">Check-in Date</option>
                  <option value="check_out">Check-out Date</option>
                  <option value="booking_date">Booking Date</option>
                </select>
              </div>
            </div>
            <select
              className="px-4 py-2 border rounded-lg"
              value={filters.status}
              onChange={e => setFilters(prev => ({ ...prev, status: e.target.value }))}
            >
              <option value="">All Statuses</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Pending">Pending</option>
            </select>
            <select
              className="px-4 py-2 border rounded-lg"
              value={filters.source}
              onChange={e => setFilters(prev => ({ ...prev, source: e.target.value }))}
            >
              <option value="">All Sources</option>
              <option value="Direct">Direct</option>
              <option value="OTA">OTA</option>
              <option value="Corporate">Corporate</option>
            </select>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 flex items-center gap-2"
            >
              Reset Filters
            </button>
            <button
              onClick={handleExport}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Export
            </button>
            <button
              onClick={() => fetchBookings()}
              className="px-4 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 flex items-center gap-2"
            >
              <RefreshCw className="w-4 h-4" />
              Refresh
            </button>
          </div>
        </div>
      </div>

      {/* Bookings Table */}
      <div className={`bg-white rounded-lg shadow-sm border border-gray-100 ${isRefetching ? 'opacity-50' : ''}`}>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                {[
                  { key: 'CreateDateTime', label: 'Date' },
                  { key: 'GuestName', label: 'Guest' },
                  { key: 'StartDate', label: 'Check In' },
                  { key: 'EndDate', label: 'Check Out' },
                  { key: 'TotalAmount', label: 'Amount' },
                  { key: 'CurrentStatus', label: 'Status' },
                  { key: 'Source', label: 'Source' }
                ].map(({ key, label }) => (
                  <th
                    key={key}
                    onClick={() => handleSort(key)}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center gap-2">
                      {label}
                      <ArrowUpDown className="w-4 h-4" />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredBookings.map((booking) => (
                <tr key={booking.BookingID} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(booking.CreateDateTime), 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{booking.GuestName}</div>
                    <div className="text-sm text-gray-500">{booking.Email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(booking.StartDate), 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(booking.EndDate), 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {(booking.TotalAmount || 0).toLocaleString('en-US', { 
                        style: 'currency', 
                        currency: booking.CurrencyCode || 'USD'
                      })}
                    </div>
                    <div className="text-xs text-gray-500">
                      Net: {(booking.NetRevenue || 0).toLocaleString('en-US', { 
                        style: 'currency', 
                        currency: booking.CurrencyCode || 'USD'
                      })}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                      ${booking.CurrentStatus === 'Confirmed' ? 'bg-green-100 text-green-800' : 
                        booking.CurrentStatus === 'Cancelled' ? 'bg-red-100 text-red-800' : 
                        'bg-yellow-100 text-yellow-800'}`}>
                      {booking.CurrentStatus}
                    </span>
                    <div className="text-xs text-gray-500 mt-1">
                      {booking.PaymentStatus}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{booking.Source}</div>
                    <div className="text-xs text-gray-500">{booking.source_channel}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Loading Overlay */}
        {isRefetching && (
          <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        )}

        {/* Pagination */}
        {pagination && (
          <div className={`px-6 py-4 border-t border-gray-200 ${isRefetching ? 'opacity-50' : ''}`}>
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-500">
                Showing {((pagination.page - 1) * pagination.pageSize) + 1} to {Math.min(pagination.page * pagination.pageSize, pagination.total)} of {pagination.total} results
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => setPage(prev => Math.max(1, prev - 1))}
                  disabled={page === 1}
                  className="px-4 py-2 border rounded-lg disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  onClick={() => setPage(prev => prev + 1)}
                  disabled={!pagination.hasMore}
                  className="px-4 py-2 border rounded-lg disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Distribution Signup Modal */}
      {showDistributionModal && (
        <DistributionSignupModal
          isOpen={showDistributionModal}
          onClose={() => setShowDistributionModal(false)}
          hotelId={parseInt(hotelId || '0')}
        />
      )}
    </div>
  );
};

export default Bookings; 