import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Hotel, TrendingUp, Receipt, Zap, BarChart3, Users2, ArrowRight, CheckCircle2, Building2, Shield } from 'lucide-react';
import { motion } from 'framer-motion';

const ZanzibarLandingPage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  return (
    <div className="min-h-screen">
      {/* Hero Section with Gradient Overlay */}
      <div className="relative bg-gradient-to-br from-teal-600 to-blue-700 overflow-hidden">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
        <div className="relative">
          <nav className="container mx-auto px-6 py-8">
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center space-x-2">
                <Hotel className="h-8 w-8 text-white" />
                <span className="text-2xl font-bold text-white">HotelOnline Zanzibar</span>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setIsLoginModalOpen(true)}
                  className="text-white hover:text-teal-50 transition-colors"
                >
                  Login
                </button>
                <button
                  onClick={() => setIsRegisterModalOpen(true)}
                  className="bg-white text-teal-600 px-6 py-2 rounded-lg hover:bg-teal-50 transition-colors"
                >
                  Start for Free
                </button>
              </div>
            </motion.div>
          </nav>

          <div className="container mx-auto px-6 py-24 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 rounded-full text-white mb-8"
            >
              <Shield className="h-5 w-5" />
              <span className="font-medium">Official ZRA-Certified Integration Partner</span>
            </motion.div>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-6xl font-bold text-white mb-8 leading-tight"
            >
              The Only ZRA-Compliant <br />
              <span className="text-teal-200 bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-blue-200">
                Hotel Management Solution
              </span>
            </motion.h1>
            <p className="text-xl text-white/90 mb-12 max-w-3xl mx-auto">
              Streamline your Zanzibar hotel operations with our ZRA-integrated platform. 
              Automate tax compliance while maximizing revenue with AI-powered insights.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Link
                to="/dashboard"
                className="group bg-white text-teal-600 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-teal-50 transition-all transform hover:scale-105 flex items-center space-x-2"
              >
                <span>Get ZRA Compliant Now</span>
                <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <a
                href="#features"
                className="text-white border-2 border-white/30 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-white/10 transition-all"
              >
                Explore Features
              </a>
            </div>
            
            {/* Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
              {[
                { value: '100%', label: 'ZRA Compliance' },
                { value: '200+', label: 'Zanzibar Hotels Trust Us' },
                { value: '24/7', label: 'Local Support' }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-6 hover:bg-white/20 transition-all"
                >
                  <div className="text-4xl font-bold text-white mb-2">{stat.value}</div>
                  <div className="text-white/80">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* ZRA Integration Section */}
      <section id="features" className="py-24 bg-white">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <div className="inline-flex items-center space-x-2 px-4 py-2 bg-teal-50 rounded-full text-teal-600 mb-6">
                <Receipt className="h-5 w-5" />
                <span className="font-medium">Seamless ZRA Integration</span>
              </div>
              <h2 className="text-4xl font-bold text-gray-900 mb-8">Automated Tax Compliance + Revenue Management</h2>
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center">
                    <Receipt className="h-6 w-6 text-teal-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg text-gray-900">Real-time ZRA Reporting</h3>
                    <p className="text-gray-600">Automatic tax calculations and submissions to ZRA</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center">
                    <Shield className="h-6 w-6 text-teal-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg text-gray-900">Full Compliance</h3>
                    <p className="text-gray-600">Meet all ZRA requirements with our certified solution</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center">
                    <Users2 className="h-6 w-6 text-teal-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg text-gray-900">Local Support Team</h3>
                    <p className="text-gray-600">Dedicated support in Zanzibar for immediate assistance</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="aspect-square bg-gradient-to-br from-teal-100 to-blue-100 rounded-full absolute inset-0" />
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1544644181-1484b3fdfc62?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                  alt="Zanzibar Beach Resort" 
                  className="rounded-lg shadow-xl"
                />
                <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-lg shadow-lg">
                  <div className="flex items-center space-x-3">
                    <Shield className="h-8 w-8 text-teal-600" />
                    <div>
                      <p className="text-2xl font-bold text-gray-900">100%</p>
                      <p className="text-sm text-gray-600">ZRA Compliant</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-teal-50 rounded-full text-teal-600 mb-6">
              <Zap className="h-5 w-5" />
              <span className="font-medium">All-in-One Solution</span>
            </div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Everything Your Zanzibar Property Needs</h2>
            <p className="text-xl text-gray-600">Comprehensive management solution with built-in ZRA compliance</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center mb-6">
                <Receipt className="h-6 w-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">ZRA Integration</h3>
              <p className="text-gray-600 mb-4">Automated tax compliance and reporting.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Real-time tax calculations</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Automatic submissions</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Compliance reports</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center mb-6">
                <BarChart3 className="h-6 w-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">Revenue Management</h3>
              <p className="text-gray-600 mb-4">Maximize your property's revenue potential.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Dynamic pricing</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Market insights</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Competitor analysis</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-teal-50 rounded-lg flex items-center justify-center mb-6">
                <Building2 className="h-6 w-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">Property Management</h3>
              <p className="text-gray-600 mb-4">Streamline your daily operations.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Booking management</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Channel distribution</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-teal-600" />
                  <span className="text-gray-600">Inventory control</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-24 bg-gradient-to-br from-teal-600 to-blue-700 overflow-hidden">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
        <div className="relative container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">Ready to Get ZRA Compliant?</h2>
          <p className="text-xl text-white/90 mb-12 max-w-2xl mx-auto">
            Join hundreds of Zanzibar properties already using HotelOnline for seamless 
            ZRA compliance and revenue optimization.
          </p>
          <button
            onClick={() => setIsRegisterModalOpen(true)}
            className="inline-flex items-center space-x-2 bg-white text-teal-600 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-teal-50 transition-all transform hover:scale-105"
          >
            <span>Start for Free</span>
            <ArrowRight className="h-5 w-5" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default ZanzibarLandingPage; 