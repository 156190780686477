import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Hotel, TrendingUp, DollarSign, Zap, BarChart3, Users2, ArrowRight, CheckCircle2, Building2, Brain, X, Mail, Lock, Globe, ChevronDown, Star, Trophy, MessageCircle, Database } from 'lucide-react';
import Logo from '../components/common/Logo';
import LoginModal from '../components/auth/LoginModal';
import { RegisterModal } from '../components/auth/RegisterModal';

const successStories = [
  {
    name: "Eka Hotel Nairobi",
    location: "Nairobi",
    results: {
      revenueIncrease: "35%",
      timeframe: "3 months",
      occupancyGrowth: "25%"
    },
    image: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    quote: "HotelOnline's technology and revenue management expertise helped us increase our revenue by 35% in just 3 months.",
    fullDescription: "The combination of AI-powered insights and expert revenue management has transformed how we price our rooms and manage our distribution channels."
  },
  {
    name: "Mestil Hotel Kampala",
    location: "Kampala",
    results: {
      revenueIncrease: "40%",
      timeframe: "6 months",
      occupancyGrowth: "30%"
    },
    image: "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    quote: "The combination of AI insights and expert guidance has transformed our revenue strategy completely.",
    fullDescription: "With HotelOnline's complete solution, we've optimized our pricing, improved our online presence, and significantly increased our direct bookings."
  },
  {
    name: "Ubumwe Grande Hotel",
    location: "Kigali",
    results: {
      revenueIncrease: "45%",
      timeframe: "4 months",
      occupancyGrowth: "35%"
    },
    image: "https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    quote: "Our occupancy rates and average daily rates have both increased significantly since partnering with HotelOnline.",
    fullDescription: "The all-in-one platform has streamlined our operations while the revenue management team has helped us maximize our revenue potential."
  }
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [isChristmasPeriod, setIsChristmasPeriod] = useState(false);
  const [isNewYearPeriod, setIsNewYearPeriod] = useState(false);
  const [selectedStory, setSelectedStory] = useState<{
    name: string;
    location: string;
    results: {
      revenueIncrease: string;
      timeframe: string;
      occupancyGrowth: string;
    };
    image: string;
    quote: string;
    fullDescription?: string;
  } | null>(null);

  const countries = [
    { code: 'ke', name: 'Kenya', path: '/kenya', flag: '🇰🇪' },
    { code: 'ug', name: 'Uganda', path: '/uganda', flag: '🇺🇬' },
    { code: 'rw', name: 'Rwanda', path: '/rwanda', flag: '🇷🇼' },
    { code: 'no', name: 'Norway', path: '/norway', flag: '🇳🇴' },
    { code: 'vn', name: 'Vietnam', path: '/vietnam', flag: '🇻🇳' },
    { code: 'tz', name: 'Zanzibar', path: '/zanzibar', flag: '🇹🇿' },
    { code: 'ng', name: 'Nigeria', path: '/nigeria', flag: '🇳🇬' }
  ];

  useEffect(() => {
    const checkFestivePeriod = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const date = today.getDate();

      // Check for Christmas period (Dec 1-26)
      const christmasEnd = new Date(year, 11, 26, 23, 59, 59);
      const christmasStart = new Date(year, 11, 1);
      const isChristmas = today >= christmasStart && today <= christmasEnd;

      // Check for New Year period (Dec 27 - Jan 5)
      const newYearStart = new Date(year, 11, 27);
      const newYearEnd = new Date(year, 0, 5, 23, 59, 59);
      const isNewYear = 
        (month === 11 && date >= 27) || // December after 27th
        (month === 0 && date <= 5);     // January before 5th

      setIsChristmasPeriod(isChristmas);
      setIsNewYearPeriod(isNewYear);
    };

    checkFestivePeriod();
  }, []);

  const snowflakes = ['❅', '❆', '❄'];
  const newYearEmojis = ['✨', '🎉', '🎊'];

  const handleStartFreeClick = () => {
    setShowRegisterModal(true);
  };

  const handleRegistrationSuccess = () => {
    navigate('/dashboard');
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "HotelOnline Revenue Management",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web-based",
    "description": "AI-powered hotel revenue management platform with dedicated revenue managers. Increase your hotel revenue by 25% or more.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "description": isChristmasPeriod ? "Holiday Special: Get 25% off your first 3 months!" : "Start for free"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1000",
      "bestRating": "5",
      "worstRating": "1"
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {isNewYearPeriod 
            ? '✨ New Year Special: Transform Your Hotel in 2024 | HotelOnline'
            : isChristmasPeriod 
              ? '🎄 Holiday Special: Boost Your Hotel Revenue | HotelOnline'
              : 'Boost Your Hotel Revenue | HotelOnline'}
        </title>
        <meta name="description" content="Transform your hotel's revenue with AI-powered insights and dedicated revenue managers. Join 1000+ hotels already increasing their revenue by 25% or more." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://go.hotelonline.co" />
        <meta property="og:title" content={isNewYearPeriod ? '✨ New Year Special: Transform Your Hotel in 2024 | HotelOnline' : isChristmasPeriod ? '🎄 Holiday Special: Boost Your Hotel Revenue | HotelOnline' : 'Boost Your Hotel Revenue | HotelOnline'} />
        <meta property="og:description" content="AI-powered revenue management platform with dedicated revenue managers. Increase your hotel revenue by 25% or more." />
        <meta property="og:image" content="https://go.hotelonline.co/og-image.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://go.hotelonline.co" />
        <meta property="twitter:title" content={isNewYearPeriod ? '✨ New Year Special: Transform Your Hotel in 2024 | HotelOnline' : isChristmasPeriod ? '🎄 Holiday Special: Boost Your Hotel Revenue | HotelOnline' : 'Boost Your Hotel Revenue | HotelOnline'} />
        <meta property="twitter:description" content="AI-powered revenue management platform with dedicated revenue managers. Increase your hotel revenue by 25% or more." />
        <meta property="twitter:image" content="https://go.hotelonline.co/twitter-image.jpg" />

        {/* Additional SEO tags */}
        <meta name="keywords" content="hotel revenue management, AI revenue management, hotel technology, revenue optimization, hotel booking engine, channel manager, property management system" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="HotelOnline" />
        <link rel="canonical" href="https://go.hotelonline.co" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="min-h-screen">
        <div className={`relative ${
          isNewYearPeriod 
            ? 'bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900' 
            : isChristmasPeriod 
              ? 'bg-gradient-to-br from-red-700 to-green-700'
              : 'bg-gradient-to-br from-secondary to-primary'
        } overflow-hidden`}>
          
          {/* Festive Animation Elements */}
          {(isChristmasPeriod || isNewYearPeriod) && (
            <div className="absolute inset-0 pointer-events-none">
              {[...Array(15)].map((_, i) => (
                <div
                  key={i}
                  className={`absolute text-white text-2xl opacity-70 animate-fall-${i % 5 + 1}`}
                  style={{
                    left: `${(i * 7) % 100}%`,
                    animationDelay: `${i * 0.3}s`,
                    animationDuration: `${4 + (i % 3)}s`
                  }}
                >
                  {isNewYearPeriod 
                    ? newYearEmojis[i % newYearEmojis.length]
                    : snowflakes[i % snowflakes.length]}
                </div>
              ))}
            </div>
          )}

          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
          <div className="relative">
            <nav className="container mx-auto px-6 py-8">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <Logo />
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setIsLoginModalOpen(true)}
                    className="text-white hover:text-primary-50 transition-colors"
                  >
                    Login
                  </button>
                  <button
                    onClick={handleStartFreeClick}
                    className={`${
                      isChristmasPeriod 
                        ? 'bg-red-500 hover:bg-red-600 text-white' 
                        : 'bg-white text-primary hover:bg-primary-50'
                    } px-6 py-2 rounded-lg transition-colors`}
                  >
                    Start for Free
                  </button>
                </div>
              </div>
            </nav>

            <div className="container mx-auto px-6 py-24 text-center">
              {/* Festive Banner */}
              {(isChristmasPeriod || isNewYearPeriod) && (
                <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 rounded-full text-white mb-8 animate-bounce">
                  <span className="font-medium">
                    {isNewYearPeriod 
                      ? '✨ New Year Special: Start 2024 with 30% off your first 3 months! 🎊'
                      : '🎄 Holiday Special: Get 25% off your first 3 months! 🎅'}
                  </span>
                </div>
              )}

              {/* Main Heading */}
              <h1 className="text-6xl font-bold text-white mb-8 leading-tight">
                {isNewYearPeriod ? (
                  <>
                    New Year, New Revenue Goals <br />
                    <span className="text-blue-200">Transform Your Hotel in 2024 ✨</span>
                  </>
                ) : isChristmasPeriod ? (
                  <>
                    Give Your Hotel the Gift of <br />
                    <span className="text-red-200">Increased Revenue 🎁</span>
                  </>
                ) : (
                  <>
                    Boost Your Hotel Revenue by <br />
                    <span className="text-primary-200">25% or More</span>
                  </>
                )}
              </h1>
              <p className="text-xl text-white/90 mb-12 max-w-4xl mx-auto">
                Combine AI-powered technology with a dedicated Revenue Manager to maximize your profits. 
                Join thousands of hotels already increasing their revenue with our hybrid approach.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <button
                  onClick={() => setShowRegisterModal(true)}
                  className="group bg-white text-primary px-8 py-4 rounded-lg text-lg font-semibold hover:bg-primary-50 transition-all transform hover:scale-105 flex items-center space-x-2"
                >
                  <span>Start Boosting Revenue</span>
                  <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </button>
                <a
                  href="#revenue-manager"
                  className="text-white border-2 border-white/30 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-white/10 transition-all"
                >
                  Meet Your Revenue Manager
                </a>
              </div>
              
              {/* Stats Section */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
                <div className={`${isChristmasPeriod ? 'christmas-card' : 'bg-white/10'} backdrop-blur-lg rounded-xl p-6`}>
                  <div className="text-4xl font-bold text-white mb-2">
                    {isChristmasPeriod && '🎁'} 25%+
                  </div>
                  <div className="text-white/80">Average Revenue Increase</div>
                </div>
                <div className={`${isChristmasPeriod ? 'christmas-card' : 'bg-white/10'} backdrop-blur-lg rounded-xl p-6`}>
                  <div className="text-4xl font-bold text-white mb-2">
                    {isChristmasPeriod && '🎄'} All-in-One
                  </div>
                  <div className="text-white/80">Complete Hotel Solution</div>
                </div>
                <div className={`${isChristmasPeriod ? 'christmas-card' : 'bg-white/10'} backdrop-blur-lg rounded-xl p-6`}>
                  <div className="text-4xl font-bold text-white mb-2">
                    {isChristmasPeriod && '⭐'} 1000+
                  </div>
                  <div className="text-white/80">Hotels Trust Our Platform</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Revenue Manager Section */}
        <section id="revenue-manager" className="py-24 bg-white">
          <div className="container mx-auto px-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              <div>
                <div className="inline-flex items-center space-x-2 px-4 py-2 bg-primary-50 rounded-full text-primary mb-6">
                  <Users2 className="h-5 w-5" />
                  <span className="font-medium">Your Complete Solution</span>
                </div>
                <h2 className="text-4xl font-bold text-secondary mb-8">Transform Your Hotel Performance</h2>
                <div className="space-y-8">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center">
                      <Users2 className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg text-secondary">Your Personal Revenue Expert</h3>
                      <p className="text-gray-600">
                        Gain a dedicated revenue manager who works around the clock, maximizing your revenue 
                        and fine-tuning every detail for peak performance.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center">
                      <Brain className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg text-secondary">AI-Powered Systems for World-Class Performance</h3>
                      <p className="text-gray-600">
                        With our cloud-based PMS, Channel Manager, Booking Engine, and more, streamline your entire 
                        operation while unlocking new revenue potential—all backed by advanced AI.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center">
                      <MessageCircle className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg text-secondary">24/7 Expert Support</h3>
                      <p className="text-gray-600">
                        From tech troubleshooting to OTA and revenue management strategies, our support team 
                        is available anytime to ensure your success at every step.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="aspect-square bg-gradient-to-br from-primary/10 to-secondary/10 rounded-full absolute inset-0" />
                <div className="relative">
                  <img 
                    src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                    alt="Revenue Manager" 
                    className="rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-lg shadow-lg">
                    <div className="flex items-center space-x-3">
                      <TrendingUp className="h-8 w-8 text-primary" />
                      <div>
                        <p className="text-2xl font-bold text-secondary">25%</p>
                        <p className="text-sm text-gray-600">Average Growth</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Technology Activation Section */}
        <section className="py-24 bg-gray-50">
          <div className="container mx-auto px-6">
            <div className="text-center mb-16">
              <div className="inline-flex items-center space-x-2 px-4 py-2 bg-primary-50 rounded-full text-primary mb-6">
                <Zap className="h-5 w-5" />
                <span className="font-medium">Complete Hotel Solution</span>
              </div>
              <h2 className="text-4xl font-bold text-secondary mb-4">Everything You Need to Succeed</h2>
              <p className="text-xl text-gray-600">Powerful tools and expert support for maximum revenue</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Instant Activation */}
              <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
                <div className="w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center mb-6">
                  <Zap className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-secondary">Instant Activation</h3>
                <p className="text-gray-600 mb-6">No technical setup required. Start optimizing revenue in minutes.</p>
                <ul className="space-y-3">
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">5-minute onboarding</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Pre-built integrations</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Immediate insights</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">24/7 support</span>
                  </li>
                </ul>
              </div>

              {/* All-in-One Hotel Tech */}
              <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
                <div className="w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center mb-6">
                  <Database className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-secondary">All-in-One Hotel Tech</h3>
                <p className="text-gray-600 mb-6">Complete hotel management system with everything you need.</p>
                <ul className="space-y-3">
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Property Management System</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Channel Manager</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Booking Engine</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Global Distribution (GDS)</span>
                  </li>
                </ul>
              </div>

              {/* Revenue Management */}
              <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
                <div className="w-12 h-12 bg-primary-50 rounded-lg flex items-center justify-center mb-6">
                  <Brain className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-secondary">Revenue Management</h3>
                <p className="text-gray-600 mb-6">AI-powered insights and expert guidance for optimal results.</p>
                <ul className="space-y-3">
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Dedicated Revenue Manager</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">AI-Powered Analytics</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Performance Dashboard</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    <span className="text-gray-600">Market Intelligence</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Success Stories Section */}
        <section id="success-stories" className="py-24 bg-white">
          <div className="container mx-auto px-6">
            <div className="text-center mb-16">
              <div className="inline-flex items-center space-x-2 px-4 py-2 bg-primary-50 rounded-full text-primary mb-6">
                <Trophy className="h-5 w-5" />
                <span className="font-medium">Success Stories</span>
              </div>
              <h2 className="text-4xl font-bold text-secondary mb-4">Real Results from Real Hotels</h2>
              <p className="text-xl text-gray-600">See how hotels across Africa are transforming their revenue</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Success Story Cards */}
              {successStories.map((story, index) => (
                <div 
                  key={index}
                  onClick={() => setSelectedStory(story)}
                  className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-all transform hover:scale-105 cursor-pointer overflow-hidden"
                >
                  <img 
                    src={story.image} 
                    alt={story.name}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">{story.name}</h3>
                    <div className="flex items-center space-x-1 mb-4">
                      <Star className="h-5 w-5 text-primary" fill="currentColor" />
                      <Star className="h-5 w-5 text-primary" fill="currentColor" />
                      <Star className="h-5 w-5 text-primary" fill="currentColor" />
                      <Star className="h-5 w-5 text-primary" fill="currentColor" />
                      <Star className="h-5 w-5 text-primary" fill="currentColor" />
                    </div>
                    <p className="text-gray-600 mb-4">"{story.quote}"</p>
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-primary font-semibold">{story.results.revenueIncrease} Revenue Growth</span>
                      <span className="text-gray-500">{story.location}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Success Story Modal */}
            {selectedStory && (
              <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
                <div className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
                  <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-2xl font-bold text-gray-900">{selectedStory.name}</h2>
                      <button 
                        onClick={() => setSelectedStory(null)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <X className="h-6 w-6" />
                      </button>
                    </div>

                    <img 
                      src={selectedStory.image} 
                      alt={selectedStory.name}
                      className="w-full h-64 object-cover rounded-lg mb-6"
                    />

                    <div className="grid grid-cols-3 gap-4 bg-primary-50 p-4 rounded-lg mb-6">
                      <div>
                        <div className="text-2xl font-bold text-primary">{selectedStory.results.revenueIncrease}</div>
                        <div className="text-sm text-gray-600">Revenue Growth</div>
                      </div>
                      <div>
                        <div className="text-2xl font-bold text-primary">{selectedStory.results.occupancyGrowth}</div>
                        <div className="text-sm text-gray-600">Occupancy Growth</div>
                      </div>
                      <div>
                        <div className="text-2xl font-bold text-primary">{selectedStory.results.timeframe}</div>
                        <div className="text-sm text-gray-600">Timeframe</div>
                      </div>
                    </div>

                    <p className="text-gray-600 mb-8">{selectedStory.fullDescription || selectedStory.quote}</p>

                    <button
                      onClick={() => {
                        setSelectedStory(null);
                        setShowRegisterModal(true);
                      }}
                      className="w-full bg-primary text-white py-4 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center space-x-2"
                    >
                      <span>Start Your Success Story Now</span>
                      <ArrowRight className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Call to Action Box */}
            <div className="mt-16 bg-primary-50 rounded-xl p-8 text-center">
              <h3 className="text-2xl font-bold text-secondary mb-4">Ready to Transform Your Hotel's Revenue?</h3>
              <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
                Join these successful hotels and start your journey to increased revenue today.
              </p>
              <button
                onClick={() => setShowRegisterModal(true)}
                className="bg-primary text-white px-8 py-4 rounded-lg hover:bg-primary-600 transition-colors inline-flex items-center space-x-2"
              >
                <span>Start Your Success Story Now</span>
                <ArrowRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </section>

        {/* Login Modal */}
        {isLoginModalOpen && (
          <LoginModal onClose={() => setIsLoginModalOpen(false)} />
        )}

        {/* Register Modal */}
        {showRegisterModal && (
          <RegisterModal 
            isOpen={showRegisterModal}
            onClose={() => setShowRegisterModal(false)}
            onSuccess={handleRegistrationSuccess}
          />
        )}
      </div>
    </>
  );
};

export default LandingPage;