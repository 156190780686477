import React, { useState } from 'react';
import { useHotel } from '../../contexts/HotelContext';
import { Search, X, Hotel, ArrowRight, CheckCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { SearchHotel } from '../../types';
import api from '../../api';

export interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onClose, onSuccess }) => {
  const { registerUser, searchHotels } = useHotel();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    phone: '',
    hotel_id: '',
    hotelName: ''
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchHotel[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<SearchHotel | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }
    setIsSearching(true);
    try {
      const response = await api.get(`/api/hotels/search`, {
        params: { q: term }
      });
      
      const results = Array.isArray(response.data) ? response.data : [];
      setSearchResults(results.map(hotel => ({
        id: hotel.id.toString(),
        name: hotel.name,
        location: hotel.location
      })));
    } catch (error) {
      console.error('Search error:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    if (value.length >= 2) {
      handleSearch(value);
    } else {
      setSearchResults([]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!selectedHotel?.id) {
      setError('Please select a hotel');
      return;
    }

    try {
      const response = await registerUser({
        email: formData.email,
        password: formData.password,
        name: formData.fullName,
        phone: formData.phone,
        hotel_id: selectedHotel.id.toString()
      });

      if (response) {
        await login(formData.email, formData.password);
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      }
    } catch (err: any) {
      console.error('Registration error:', err);
      setError(err.response?.data?.error || 'Registration failed. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-8">
          {/* Header Section */}
          <div className="flex justify-between items-start mb-8">
            <div>
              <div className="flex items-center space-x-2 mb-2">
                <Hotel className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-bold text-gray-900">Start Your Success Story</h2>
              </div>
              <p className="text-gray-600">Join 1000+ hotels already achieving 25%+ revenue growth</p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="h-6 w-6" />
            </button>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg flex items-center space-x-2">
              <span className="text-sm">{error}</span>
            </div>
          )}

          <form 
            onSubmit={handleSubmit} 
            className="space-y-6"
            autoComplete="off"
          >
            <input type="text" style={{ display: 'none' }} />
            <input type="password" style={{ display: 'none' }} />
            {/* Hotel Search Section */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Find Your Hotel
                </label>
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    className="pl-10 w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Search by hotel name or location..."
                    autoComplete="off"
                    role="searchbox"
                    aria-label="Search hotels"
                  />
                </div>

                {searchTerm.length >= 2 && (
                  <div className="mt-2 max-h-60 overflow-y-auto border border-gray-100 rounded-lg shadow-sm">
                    {isSearching ? (
                      <div className="p-4 text-center text-gray-500">Searching...</div>
                    ) : searchResults.length === 0 ? (
                      <div className="p-4 text-center text-gray-500">No hotels found</div>
                    ) : (
                      searchResults.map((hotel) => (
                        <div
                          key={hotel.id}
                          onClick={() => {
                            setSelectedHotel(hotel);
                            setSearchTerm('');
                          }}
                          className="p-4 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-b-0"
                        >
                          <div className="font-medium text-gray-900">{hotel.name}</div>
                          <div className="text-sm text-gray-500">{hotel.location}</div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>

              {selectedHotel && (
                <div className="p-4 bg-primary-50 rounded-lg border border-primary-100">
                  <div className="flex items-center space-x-2">
                    <CheckCircle className="h-5 w-5 text-primary" />
                    <div>
                      <div className="font-medium text-primary">{selectedHotel.name}</div>
                      <div className="text-sm text-gray-600">{selectedHotel.location}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* User Details */}
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={formData.fullName}
                    onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  required
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    required
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-primary text-white p-4 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center space-x-2 font-semibold"
            >
              <span>Create Free Account</span>
              <ArrowRight className="h-5 w-5" />
            </button>

            <p className="text-center text-sm text-gray-500">
              By creating an account, you agree to our{' '}
              <a href="#" className="text-primary hover:underline">Terms of Service</a>
              {' '}and{' '}
              <a href="#" className="text-primary hover:underline">Privacy Policy</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}; 