import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Guest } from './columns';
import { format } from 'date-fns';

interface GuestDetailsModalProps {
  guest: Guest | null;
  isOpen: boolean;
  onClose: () => void;
}

export function GuestDetailsModal({ guest, isOpen, onClose }: GuestDetailsModalProps) {
  if (!guest) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Guest Details</DialogTitle>
        </DialogHeader>
        
        <div className="grid grid-cols-2 gap-4 py-4">
          <div className="space-y-4">
            <h3 className="font-semibold">Personal Information</h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div className="font-medium">Name:</div>
              <div>{`${guest.salutation || ''} ${guest.first_name || ''} ${guest.last_name || ''}`.trim() || '-'}</div>
              
              <div className="font-medium">Email:</div>
              <div>{guest.email || '-'}</div>
              
              <div className="font-medium">Phone:</div>
              <div>{guest.mobile || guest.phone || '-'}</div>
              
              <div className="font-medium">Gender:</div>
              <div>{guest.gender || '-'}</div>
              
              <div className="font-medium">Country:</div>
              <div>{guest.country || '-'}</div>
              
              <div className="font-medium">City:</div>
              <div>{guest.city || '-'}</div>
              
              <div className="font-medium">State:</div>
              <div>{guest.state || '-'}</div>
              
              <div className="font-medium">ID Type:</div>
              <div>{guest.identity_type || '-'}</div>
              
              <div className="font-medium">ID Number:</div>
              <div>{guest.identity_number || '-'}</div>
            </div>
          </div>
          
          <div className="space-y-4">
            <h3 className="font-semibold">Booking History</h3>
            <div className="space-y-4 max-h-[400px] overflow-y-auto">
              {guest.bookings && guest.bookings.length > 0 ? (
                guest.bookings.map((booking, index) => (
                  <div key={booking.BookingID} className="border rounded p-3 text-sm">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="font-medium">Booking ID:</div>
                      <div>{booking.BookingID}</div>
                      
                      <div className="font-medium">Check-in:</div>
                      <div>{format(new Date(booking.StartDate), 'PP')}</div>
                      
                      <div className="font-medium">Check-out:</div>
                      <div>{format(new Date(booking.EndDate), 'PP')}</div>
                      
                      <div className="font-medium">Room Type:</div>
                      <div>{booking.RoomTypeName}</div>
                      
                      <div className="font-medium">Status:</div>
                      <div>{booking.CurrentStatus}</div>
                      
                      <div className="font-medium">Amount:</div>
                      <div>{booking.TotalAmount}</div>
                      
                      <div className="font-medium">Source:</div>
                      <div>{booking.Source}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-muted-foreground">No booking history available</div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 