import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../components/common/Logo';
import LoginModal from '../components/auth/LoginModal';
import { RegisterModal } from '../components/auth/RegisterModal';

const NewYearLandingPage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [selectedStory, setSelectedStory] = useState<any>(null);

  // ... rest of the code remains the same

  return (
    <>
      <Helmet>
        <title>✨ New Year Special: Transform Your Hotel in 2024 | HotelOnline</title>
        {/* Keep same meta tags but update content for New Year */}
      </Helmet>

      <div className="min-h-screen">
        <div className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 overflow-hidden">
          {/* New Year Animation */}
          <div className="absolute inset-0 pointer-events-none">
            {[...Array(15)].map((_, i) => (
              <div
                key={i}
                className={`absolute text-white text-2xl opacity-70 animate-fall-${i % 5 + 1}`}
                style={{
                  left: `${(i * 7) % 100}%`,
                  animationDelay: `${i * 0.3}s`,
                  animationDuration: `${4 + (i % 3)}s`
                }}
              >
                {['✨', '🎉', '🎊'][i % 3]}
              </div>
            ))}
          </div>

          {/* Rest of the New Year specific content */}
          <div className="container mx-auto px-6 py-24 text-center">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 rounded-full text-white mb-8 animate-bounce">
              <span className="font-medium">
                ✨ New Year Special: Start 2024 with 30% off your first 3 months! 🎊
              </span>
            </div>

            <h1 className="text-6xl font-bold text-white mb-8 leading-tight">
              New Year, New Revenue Goals <br />
              <span className="text-blue-200">Transform Your Hotel in 2024 ✨</span>
            </h1>
            
            {/* Rest of the content similar to original but with New Year theme */}
          </div>
        </div>

        {/* Keep other sections but with New Year specific messaging */}
      </div>
    </>
  );
};

export default NewYearLandingPage; 