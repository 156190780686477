import React from 'react';
import { Box, Container, Paper, Typography, Grid } from '@mui/material';
import TaxiOrderSection from '../components/TaxiOrderSection';
import TaxiBookingHistory from '../components/TaxiBookingHistory';

const TaxiServices = () => {
  return (
    <div>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Taxi Services
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <TaxiOrderSection />
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <TaxiBookingHistory />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaxiServices; 