import React, { useState } from 'react';
import { Box, Button, FormControl, TextField, Typography, MenuItem, Alert, CircularProgress, Grid } from '@mui/material';

const TaxiOrderSection = () => {
  const [bookingData, setBookingData] = useState({
    pickupLocation: '',
    dropoffLocation: '',
    pickupTime: '',
    passengerCount: 1,
    rideType: 'fromHotel',
    specialRequirements: '',
    contactNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ message: '', type: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiEndpoint = 'https://api.taxi-v2.dev.webdev.com/v1/bookings';
      const formattedData = {
        ...bookingData,
        hotelLocation: 'Your Hotel Address',
        timestamp: new Date().toISOString(),
        status: 'pending'
      };

      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_API_KEY'
        },
        body: JSON.stringify(formattedData)
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setStatus({ 
          message: `Taxi booked successfully! Booking ID: ${data.bookingId}`, 
          type: 'success' 
        });
        setBookingData({
          pickupLocation: '',
          dropoffLocation: '',
          pickupTime: '',
          passengerCount: 1,
          rideType: 'fromHotel',
          specialRequirements: '',
          contactNumber: ''
        });
      } else {
        setStatus({ 
          message: data.message || 'Failed to book taxi', 
          type: 'error' 
        });
      }
    } catch (error) {
      setStatus({ 
        message: 'Error connecting to taxi service', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {status.message && (
        <Alert severity={status.type} sx={{ mb: 3 }}>
          {status.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                label="Ride Type"
                value={bookingData.rideType}
                onChange={(e) => setBookingData({...bookingData, rideType: e.target.value})}
                sx={{ mb: 2 }}
              >
                <MenuItem value="fromHotel">From Hotel</MenuItem>
                <MenuItem value="toHotel">To Hotel</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label={bookingData.rideType === 'fromHotel' ? 'Dropoff Location' : 'Pickup Location'}
                value={bookingData.rideType === 'fromHotel' ? bookingData.dropoffLocation : bookingData.pickupLocation}
                onChange={(e) => setBookingData({
                  ...bookingData,
                  [bookingData.rideType === 'fromHotel' ? 'dropoffLocation' : 'pickupLocation']: e.target.value
                })}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="datetime-local"
                label="Pickup Time"
                value={bookingData.pickupTime}
                onChange={(e) => setBookingData({...bookingData, pickupTime: e.target.value})}
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Number of Passengers"
                value={bookingData.passengerCount}
                onChange={(e) => setBookingData({...bookingData, passengerCount: e.target.value})}
                InputProps={{ inputProps: { min: 1, max: 8 } }}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Contact Number"
                value={bookingData.contactNumber}
                onChange={(e) => setBookingData({...bookingData, contactNumber: e.target.value})}
                required
                placeholder="+1234567890"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Special Requirements"
                value={bookingData.specialRequirements}
                onChange={(e) => setBookingData({...bookingData, specialRequirements: e.target.value})}
                multiline
                rows={3}
                placeholder="Any special requirements (e.g., baby seat, wheelchair access)"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              fullWidth 
              disabled={loading}
              sx={{ mt: 2, height: 48 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Book Taxi'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default TaxiOrderSection; 