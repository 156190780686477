import { Hotel, SearchHotel } from '../types';
import api from '../api';

export async function searchHotels(term: string): Promise<SearchHotel[]> {
  console.log('Searching hotels with term:', term);
  
  try {
    const response = await api.get(`/api/hotels/search`, {
      params: { q: term }
    });
    console.log('Search response:', response.data);
    return response.data || [];
  } catch (error) {
    console.error('Error searching hotels:', error);
    throw error;
  }
}

export async function fetchHotel(id: number): Promise<Hotel> {
  console.log('🔄 API: Fetching hotel from URL:', `/hotels/${id}`);
  
  try {
    const response = await api.get(`/hotels/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching hotel:', error);
    throw error;
  }
}

export async function fetchHotels(): Promise<Hotel[]> {
  try {
    const response = await api.get('/hotels');
    return response.data;
  } catch (error) {
    console.error('Error fetching hotels:', error);
    throw error;
  }
} 