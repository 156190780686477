import { Activity, TrendingUp, Users, Target, X, Check, MapPin, ArrowUpRight, ArrowDownRight, Zap } from 'lucide-react';
import { useMemo, useState } from 'react';

interface RoomPrice {
  price: number;
  availability: number;
}

interface HotelData {
  hotelName: string;
  prices: {
    [key: string]: RoomPrice;
  };
}

interface MarketPerformanceProps {
  data: HotelData[];
}

const MarketPerformance = ({ data }: MarketPerformanceProps) => {
  const [showDynamicPricingModal, setShowDynamicPricingModal] = useState(false);

  // Calculate market metrics with impressive dummy data
  const metrics = useMemo(() => {
    return {
      competitorCount: 28,
      availableRooms: 245,
      avgPrice: 189.99,
      bookingsToday: 32,
      location: "Eldoret",
      marketTrends: {
        priceChange: +12.5,
        demandChange: +18.2,
        occupancyRate: 78.5,
        revenueGrowth: +15.8
      }
    };
  }, [data]);

  return (
    <>
      {/* Location Header with Market Health Score */}
      <div className="col-span-4 flex items-center justify-between mb-2 bg-white p-4 rounded-lg shadow-sm border border-gray-100">
        <div className="flex items-center">
          <MapPin className="h-5 w-5 text-blue-500 mr-2" />
          <h2 className="text-lg font-semibold text-gray-700">Market Data for {metrics.location}</h2>
        </div>
        <div className="flex items-center bg-green-50 px-3 py-1 rounded-full">
          <Zap className="h-4 w-4 text-green-500 mr-1" />
          <span className="text-sm font-medium text-green-700">Market Health: Excellent</span>
        </div>
      </div>

      {/* Properties with Availability Card */}
      <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Users className="h-5 w-5 text-blue-500" />
            <span className="text-sm font-medium text-gray-600">Active Properties</span>
          </div>
          <div className="flex items-center">
            <span className="text-lg font-semibold text-gray-900">{metrics.competitorCount}</span>
            <div className="ml-2 flex items-center text-green-500">
              <ArrowUpRight className="h-4 w-4" />
              <span className="text-sm">+5</span>
            </div>
          </div>
        </div>
        <div className="mt-2 text-xs text-gray-500">Growing market in {metrics.location}</div>
      </div>

      {/* Market Dynamics Card */}
      <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Target className="h-5 w-5 text-blue-500" />
            <span className="text-sm font-medium text-gray-600">Market Demand</span>
          </div>
          <div className="flex items-center">
            <span className="text-lg font-semibold text-green-600">+{metrics.marketTrends.demandChange}%</span>
          </div>
        </div>
        <div className="mt-2">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div className="bg-blue-500 h-2 rounded-full" style={{ width: '78%' }}></div>
          </div>
        </div>
        <div className="mt-2 text-xs text-gray-500">Strong demand growth trend</div>
      </div>

      {/* Average Rate Card */}
      <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <TrendingUp className="h-5 w-5 text-blue-500" />
            <span className="text-sm font-medium text-gray-600">Average Rate</span>
          </div>
          <div className="flex items-center">
            <span className="text-lg font-semibold text-gray-900">
              ${metrics.avgPrice}
            </span>
            <div className="ml-2 flex items-center text-green-500">
              <ArrowUpRight className="h-4 w-4" />
              <span className="text-sm">+{metrics.marketTrends.priceChange}%</span>
            </div>
          </div>
        </div>
        <div className="mt-2 text-xs text-gray-500">Market rates trending upward</div>
      </div>

      {/* Revenue Opportunity Card */}
      <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100 cursor-pointer hover:bg-blue-50"
           onClick={() => setShowDynamicPricingModal(true)}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Activity className="h-5 w-5 text-blue-500" />
            <span className="text-sm font-medium text-gray-600">Revenue Growth</span>
          </div>
          <div className="flex items-center">
            <span className="text-lg font-semibold text-green-600">+{metrics.marketTrends.revenueGrowth}%</span>
          </div>
        </div>
        <div className="mt-2 text-xs text-blue-600 flex items-center">
          Click to explore revenue opportunities
          <ArrowUpRight className="h-4 w-4 ml-1" />
        </div>
      </div>

      {/* Dynamic Pricing Modal */}
      {showDynamicPricingModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-6 border-b">
              <h2 className="text-2xl font-bold text-gray-900">Revenue Optimization Opportunities</h2>
              <button onClick={() => setShowDynamicPricingModal(false)}>
                <X className="h-6 w-6 text-gray-500 hover:text-gray-700" />
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6">
              <div className="space-y-8">
                {/* Market Insights Section */}
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-blue-900 mb-3">Current Market Insights</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg">
                      <div className="text-sm text-gray-600">Occupancy Rate</div>
                      <div className="text-xl font-bold text-blue-600">{metrics.marketTrends.occupancyRate}%</div>
                    </div>
                    <div className="bg-white p-3 rounded-lg">
                      <div className="text-sm text-gray-600">Revenue Growth</div>
                      <div className="text-xl font-bold text-green-600">+{metrics.marketTrends.revenueGrowth}%</div>
                    </div>
                  </div>
                </div>

                {/* Opportunity Cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="border rounded-lg p-4 hover:shadow-lg transition-shadow">
                    <h4 className="font-semibold text-lg mb-2">Connect Revenue Manager</h4>
                    <p className="text-gray-600 mb-4">Get personalized pricing strategies and market insights</p>
                    <button className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full">
                      Start Free Trial
                    </button>
                  </div>
                  <div className="border rounded-lg p-4 hover:shadow-lg transition-shadow">
                    <h4 className="font-semibold text-lg mb-2">Join Conference.Place</h4>
                    <p className="text-gray-600 mb-4">Tap into the growing MICE market segment</p>
                    <button className="bg-green-600 text-white px-4 py-2 rounded-lg w-full">
                      Learn More
                    </button>
                  </div>
                </div>

                {/* Additional Opportunities */}
                <div>
                  <h3 className="text-lg font-semibold mb-4">More Growth Opportunities</h3>
                  <div className="space-y-3">
                    {[
                      { title: 'Dynamic Pricing Engine', desc: 'Automatically adjust rates based on real-time demand' },
                      { title: 'Market Intelligence Pro', desc: 'Get advanced competitor insights and forecasting' },
                      { title: 'Distribution Optimization', desc: 'Maximize visibility across all channels' }
                    ].map((opp) => (
                      <div key={opp.title} className="flex items-start space-x-3 p-3 border rounded-lg hover:bg-gray-50">
                        <div className="flex-shrink-0 bg-blue-100 rounded-full p-1">
                          <Zap className="w-4 h-4 text-blue-600" />
                        </div>
                        <div>
                          <h4 className="font-medium text-gray-900">{opp.title}</h4>
                          <p className="text-sm text-gray-500">{opp.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarketPerformance; 