import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CellAction } from "./cell-action";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { ArrowUpDown } from "lucide-react";

export interface Guest {
  id: number;
  salutation: string | null;
  first_name: string | null;
  last_name: string | null;
  gender: string | null;
  email: string | null;
  phone: string | null;
  mobile: string | null;
  country: string | null;
  city: string | null;
  state: string | null;
  identity_type: string | null;
  identity_number: string | null;
  created_at: string | null;
  bookings: any[];
}

export const GuestColumns: ColumnDef<Guest, any>[] = [
  {
    accessorKey: "full_name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="p-0 hover:bg-transparent text-black/60 hover:text-black font-medium"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Guest Name
          <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const guest = row.original;
      const [isEditing, setIsEditing] = useState(false);
      const [firstName, setFirstName] = useState(guest.first_name || '');
      const [lastName, setLastName] = useState(guest.last_name || '');

      if (isEditing) {
        return (
          <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
            <Input 
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="h-8 w-24"
              placeholder="First Name"
            />
            <Input 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="h-8 w-24"
              placeholder="Last Name"
            />
            <Button 
              onClick={() => {
                // TODO: Implement save functionality
                setIsEditing(false);
              }}
              size="sm"
              className="h-8"
            >
              Save
            </Button>
          </div>
        );
      }

      return (
        <div 
          className="font-medium hover:text-primary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          {`${guest.salutation || ''} ${guest.first_name || ''} ${guest.last_name || ''}`.trim() || '-'}
        </div>
      );
    },
    sortingFn: (rowA, rowB) => {
      const a = `${rowA.original.first_name || ''} ${rowA.original.last_name || ''}`.trim().toLowerCase();
      const b = `${rowB.original.first_name || ''} ${rowB.original.last_name || ''}`.trim().toLowerCase();
      return a.localeCompare(b);
    }
  },
  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="p-0 hover:bg-transparent text-black/60 hover:text-black font-medium"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const guest = row.original;
      const [isEditing, setIsEditing] = useState(false);
      const [email, setEmail] = useState(guest.email || '');

      if (isEditing) {
        return (
          <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
            <Input 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-8 w-40"
              type="email"
            />
            <Button 
              onClick={() => {
                // TODO: Implement save functionality
                setIsEditing(false);
              }}
              size="sm"
              className="h-8"
            >
              Save
            </Button>
          </div>
        );
      }

      return (
        <div 
          className="text-black/80 hover:text-primary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          {guest.email || '-'}
        </div>
      );
    }
  },
  {
    accessorKey: "phone",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="p-0 hover:bg-transparent text-black/60 hover:text-black font-medium"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Phone/Mobile
          <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const guest = row.original;
      const [isEditing, setIsEditing] = useState(false);
      const [phone, setPhone] = useState(guest.mobile || guest.phone || '');

      if (isEditing) {
        return (
          <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
            <Input 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="h-8 w-32"
              type="tel"
            />
            <Button 
              onClick={() => {
                // TODO: Implement save functionality
                setIsEditing(false);
              }}
              size="sm"
              className="h-8"
            >
              Save
            </Button>
          </div>
        );
      }

      return (
        <div 
          className="text-black/80 hover:text-primary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          {guest.mobile || guest.phone || '-'}
        </div>
      );
    }
  },
  {
    accessorKey: "country",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="p-0 hover:bg-transparent text-black/60 hover:text-black font-medium"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Country
          <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="text-black/80">
        {row.original.country || '-'}
      </div>
    )
  },
  {
    accessorKey: "bookings",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="p-0 hover:bg-transparent text-black/60 hover:text-black font-medium"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Bookings
          <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="font-medium">
        {row.original.bookings?.length || 0}
      </div>
    ),
    sortingFn: (rowA, rowB) => {
      return (rowA.original.bookings?.length || 0) - (rowB.original.bookings?.length || 0);
    }
  },
  {
    id: "actions",
    cell: ({ row }) => <CellAction data={row.original} />
  }
]; 