import React, { useCallback, useState, useMemo } from 'react';
import { useHotel } from '../../contexts/HotelContext';
import { useData } from '../../hooks/useData';
import { Star, TrendingUp, Users, BarChart } from 'lucide-react';
import { Review, ReviewTrend, HotelRanking } from '../../types/reviews';
import api from '../../api';

const DEBUG = true;

const ReviewsComponent = () => {
  const { hotel } = useHotel();
  const [selectedPeriod, setSelectedPeriod] = useState<'week' | 'month' | 'year'>('month');

  // Early return if no hotel is selected
  if (!hotel) {
    return (
      <div className="p-6">
        <p className="text-gray-500">Please select a hotel to view reviews.</p>
      </div>
    );
  }

  if (DEBUG) {
    console.log('=== DEBUG START ===');
    console.log('Hotel:', hotel);
    console.log('Hotel ID:', hotel?.id);
  }

  // Fetch reviews
  const fetchReviewsData = useCallback(async () => {
    if (!hotel?.id) {
      console.log('DEBUG: No hotel ID available for reviews fetch');
      return [] as Review[];
    }
    try {
      console.log('DEBUG: Attempting to fetch reviews for hotel:', hotel.id);
      const response = await api.get(`/api/hotels/${hotel.id}/reviews`);
      console.log('DEBUG: Fetch reviews result:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('DEBUG: Error fetching reviews:', error);
      return [] as Review[];
    }
  }, [hotel?.id]);

  // Fetch trends
  const fetchTrendsData = useCallback(async () => {
    if (!hotel?.id) {
      console.log('DEBUG: No hotel ID available for trends');
      return Promise.resolve([]);
    }
    try {
      const response = await fetchReviewTrends(hotel.id);
      return response;
    } catch (error: any) {
      console.error('Error fetching trends:', error);
      return [];
    }
  }, [hotel?.id]);

  // Fetch rankings
  const fetchRankingsData = useCallback(async () => {
    if (!hotel?.id) {
      console.log('DEBUG: No hotel ID available for rankings');
      return Promise.resolve([]);
    }
    try {
      const response = await fetchHotelRankings(hotel.id);
      return response;
    } catch (error: any) {
      console.error('Error fetching rankings:', error);
      return [];
    }
  }, [hotel?.id]);

  const { data: reviews = [], loading: reviewsLoading } = useData<Review[]>(fetchReviewsData);
  const { data: trends = [], loading: trendsLoading } = useData<ReviewTrend[]>(fetchTrendsData);
  const { data: rankings = [], loading: rankingsLoading } = useData<HotelRanking[]>(fetchRankingsData);

  if (DEBUG) {
    console.log('Reviews state:', {
      reviews,
      reviewsLoading,
      trendsLoading,
      rankingsLoading
    });
    console.log('=== DEBUG END ===');
  }

  // Move loading check before other checks
  if (reviewsLoading || trendsLoading || rankingsLoading) {
    console.log('DEBUG: Still loading...');
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  // Check for missing hotel ID
  if (!hotel?.id) {
    console.log('DEBUG: No hotel selected');
    return (
      <div className="p-6">
        <p className="text-gray-500">Please select a hotel to view reviews.</p>
      </div>
    );
  }

  // Check for empty reviews array
  if (!reviews || reviews.length === 0) {
    console.log('DEBUG: No reviews available');
    return (
      <div className="p-6">
        <p className="text-gray-500">No reviews available for this hotel.</p>
      </div>
    );
  }

  // Calculate stats
  const stats = {
    averageScore: reviews?.reduce((sum, review) => sum + review.review_score, 0) / (reviews?.length || 1) || 0,
    totalReviews: reviews?.length || 0,
    ranking: rankings?.find(r => r.hotel_id === hotel?.id)?.rank_by_score || 0,
    trend: (trends?.length || 0) > 1 
      ? (trends?.[trends.length - 1].average_score || 0) - (trends?.[trends.length - 2].average_score || 0)
      : 0
  };

  const recentReviewsCount = useMemo(() => {
    if (!trends || trends.length === 0) return 0;
    return trends[trends.length - 1].count;
  }, [trends]);

  return (
    <div className="p-6 space-y-6">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <StatsCard
          title="Average Score"
          value={stats.averageScore.toFixed(1)}
          subtitle="Out of 5.0"
          icon={Star}
          color="yellow"
        />
        <StatsCard
          title="Total Reviews"
          value={stats.totalReviews}
          subtitle="All time"
          icon={Users}
          color="blue"
        />
        <StatsCard
          title="Market Ranking"
          value={`#${stats.ranking}`}
          subtitle="In your area"
          icon={BarChart}
          color="green"
        />
        <StatsCard
          title="Score Trend"
          value={stats.trend > 0 ? `+${stats.trend.toFixed(1)}` : stats.trend.toFixed(1)}
          subtitle="vs last period"
          icon={TrendingUp}
          color={stats.trend >= 0 ? 'green' : 'red'}
        />
      </div>

      {/* Reviews Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-4 border-b">
          <h3 className="text-lg font-semibold">Recent Reviews</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Reviewer</th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Score</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Comment</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {reviews?.map((review) => (
                <tr key={review.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3 text-sm text-gray-500">
                    {new Date(review.review_date).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-3 text-sm text-gray-900">{review.reviewer_name}</td>
                  <td className="px-4 py-3 text-sm text-center">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      {review.review_score.toFixed(1)}
                    </span>
                  </td>
                  <td className="px-4 py-3 text-sm text-gray-500">
                    <div className="max-w-lg truncate">{review.review_text}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface StatsCardProps {
  title: string;
  value: string | number;
  subtitle: string;
  icon: React.FC<{ className?: string }>;
  color?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, value, subtitle, icon: Icon, color = 'primary' }) => {
  // Map color names to Tailwind classes
  const colorMap = {
    yellow: 'text-yellow-500',
    blue: 'text-blue-500',
    green: 'text-green-500',
    red: 'text-red-500',
    primary: 'text-primary-500'
  };

  const textColorClass = colorMap[color as keyof typeof colorMap] || 'text-primary-500';
  
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <Icon className={`h-5 w-5 ${textColorClass}`} />
      </div>
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
      <p className={`text-sm ${textColorClass} mt-1`}>{subtitle}</p>
    </div>
  );
};

const fetchHotelRankings = async (hotelId: number): Promise<HotelRanking[]> => {
  console.log('DEBUG: Fetching hotel rankings for:', hotelId);
  try {
    const response = await api.get(`/api/hotels/${hotelId}/rankings`);
    console.log('DEBUG: Rankings response:', response);
    return response.data;
  } catch (error: any) {
    console.error('DEBUG: Error fetching rankings:', error);
    if (error && typeof error === 'object' && 'response' in error) {
      console.error('DEBUG: Rankings error response:', error.response?.data);
    }
    return [];
  }
};

const fetchReviewTrends = async (hotelId: number): Promise<ReviewTrend[]> => {
  console.log('DEBUG: Fetching review trends for:', hotelId);
  try {
    const response = await api.get(`/api/hotels/${hotelId}/review-trends`);
    console.log('DEBUG: Trends response:', response);
    return response.data;
  } catch (error: any) {
    console.error('DEBUG: Error fetching review trends:', error);
    if (error && typeof error === 'object' && 'response' in error) {
      console.error('DEBUG: Trends error response:', error.response?.data);
    }
    return [];
  }
};

const fetchReviews = async (hotelId: number): Promise<Review[]> => {
  try {
    console.log('DEBUG: fetchReviews called with hotelId:', hotelId);
    const response = await api.get(`/api/hotels/${hotelId}/reviews`);
    console.log('DEBUG: fetchReviews response:', response);
    
    if (!response.data) {
      console.error('DEBUG: No data in response');
      return [];
    }
    
    return response.data;
  } catch (error: any) {
    console.error('DEBUG: Error in fetchReviews:', error);
    if (error && typeof error === 'object' && 'response' in error) {
      console.error('DEBUG: Error response:', error.response?.data);
    }
    return [];
  }
};

export default ReviewsComponent;
