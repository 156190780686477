import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';

interface Hotel {
  id: number;
  name: string;
  is_default?: boolean;
  location?: string;
}

interface RegisterUserData {
  email: string;
  password: string;
  name: string;
  phone: string;
  hotel_id: string;
}

interface HotelContextType {
  hotelId: number | null;
  setHotelId: (id: number | null) => void;
  hotel: Hotel | null;
  setHotel: (hotel: Hotel | null) => void;
  registerUser: (data: RegisterUserData) => Promise<any>;
  searchHotels: (term: string) => Promise<any>;
}

const HotelContext = createContext<HotelContextType | undefined>(undefined);

export const HotelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hotelId, setHotelId] = useState<number | null>(null);
  const [hotel, setHotel] = useState<Hotel | null>(null);

  // Add effect to fetch hotel data when hotelId changes
  useEffect(() => {
    const fetchHotelData = async () => {
      if (!hotelId) return;

      try {
        const response = await api.get('/api/user/hotels');
        if (response.data && Array.isArray(response.data)) {
          const foundHotel = response.data.find(h => h.id === hotelId);
          if (foundHotel) {
            console.log('Setting hotel in context:', foundHotel);
            setHotel(foundHotel);
          }
        }
      } catch (error) {
        console.error('Error fetching hotel data:', error);
      }
    };

    fetchHotelData();
  }, [hotelId]);

  // Add effect to initialize from URL if needed
  useEffect(() => {
    const initializeFromUrl = async () => {
      const pathParts = window.location.pathname.split('/');
      const urlHotelId = pathParts.includes('dashboard') ? Number(pathParts[pathParts.length - 1]) : null;
      
      if (urlHotelId && !hotelId) {
        console.log('Initializing hotel from URL:', urlHotelId);
        setHotelId(urlHotelId);
      }
    };

    initializeFromUrl();
  }, []);

  const registerUser = async (data: RegisterUserData) => {
    try {
      const response = await api.post('/api/auth/register', data);
      return response.data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const searchHotels = async (term: string) => {
    try {
      const response = await api.get('/api/hotels/search', {
        params: { q: term }
      });
      return response.data;
    } catch (error) {
      console.error('Hotel search error:', error);
      throw error;
    }
  };

  const contextValue = {
    hotelId,
    setHotelId,
    hotel,
    setHotel,
    registerUser,
    searchHotels
  };

  return (
    <HotelContext.Provider value={contextValue}>
      {children}
    </HotelContext.Provider>
  );
};

export const useHotel = () => {
  const context = useContext(HotelContext);
  if (!context) {
    throw new Error('useHotel must be used within a HotelProvider');
  }
  return context;
};

export default HotelProvider;