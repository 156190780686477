import React, { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Guest } from "./columns";

interface CellActionProps {
  data: Guest;
}

export function CellAction({ data }: CellActionProps) {
  const [showMessage, setShowMessage] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const sendWhatsApp = async () => {
    const phone = data.mobile || data.phone;
    if (!phone) return;

    await fetch('/api/messages/whatsapp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone: phone,
        guestId: data.id
      })
    });
  };

  return (
    <>
      <div className="flex gap-2">
        <Button 
          onClick={() => setShowDetails(true)}
          variant="outline"
          size="sm"
        >
          Details
        </Button>
        <Button 
          onClick={() => setShowMessage(true)}
          variant="outline"
          size="sm"
        >
          Email
        </Button>
        <Button
          onClick={sendWhatsApp}
          variant="outline" 
          size="sm"
          disabled={!data.mobile && !data.phone}
        >
          WhatsApp
        </Button>
      </div>

      {/* Guest Details Modal */}
      {showDetails && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[600px] max-h-[80vh] overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4">Guest Details</h3>
            
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <p className="text-sm text-gray-500">Name</p>
                <p className="font-medium">{`${data.salutation || ''} ${data.first_name || ''} ${data.last_name || ''}`.trim()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Gender</p>
                <p className="font-medium">{data.gender || '-'}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Email</p>
                <p className="font-medium">{data.email || '-'}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Phone/Mobile</p>
                <p className="font-medium">{data.mobile || data.phone || '-'}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Address</p>
                <p className="font-medium">
                  {[data.city, data.state, data.country].filter(Boolean).join(', ') || '-'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Identity</p>
                <p className="font-medium">
                  {data.identity_type ? `${data.identity_type}: ${data.identity_number}` : '-'}
                </p>
              </div>
            </div>

            <div className="border-t pt-4">
              <h4 className="font-medium mb-2">Bookings History</h4>
              {data.bookings && data.bookings.length > 0 ? (
                <div className="space-y-2">
                  {data.bookings.map((booking: any) => (
                    <div key={booking.id} className="p-2 bg-gray-50 rounded">
                      <p className="text-sm">
                        <span className="font-medium">#{booking.booking_number}</span> - 
                        {new Date(booking.check_in).toLocaleDateString()} to {new Date(booking.check_out).toLocaleDateString()}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-gray-500">No booking history</p>
              )}
            </div>

            <div className="flex justify-end mt-6">
              <Button variant="outline" onClick={() => setShowDetails(false)}>
                Close
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Message Modal */}
      {showMessage && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[500px]">
            <h3 className="text-lg font-semibold mb-4">
              Send Message to {`${data.first_name} ${data.last_name}`.trim()}
            </h3>
            <textarea 
              className="w-full p-2 border rounded-md mb-4"
              rows={4}
              placeholder="Enter your message..."
            />
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowMessage(false)}>
                Cancel
              </Button>
              <Button onClick={() => {
                // TODO: Implement email sending
                setShowMessage(false);
              }}>
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
} 