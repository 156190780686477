import React, { useState, useEffect } from 'react';
import { useHotel } from '../../contexts/HotelContext';
import { WalletTransaction, PaymentProvider, PreSellLimit, PreSellOffer } from '../../types/wallet';
import { MarketDataItem } from '../../types/market';
import { DollarSign, ArrowDownCircle, Wallet as WalletIcon, History, CreditCard, Calendar } from 'lucide-react';

const PAYMENT_PROVIDERS: PaymentProvider[] = [
  {
    id: 'stripe',
    name: 'Stripe',
    logo: '/stripe-logo.png',
    currencies: ['USD', 'EUR', 'GBP'],
    minAmount: 50,
    maxAmount: 10000,
    fee: 2.9,
    feeType: 'percentage',
    enabled: true,
    countries: ['US', 'UK', 'EU']
  },
  {
    id: 'flutterwave',
    name: 'Flutterwave',
    logo: '/flutterwave-logo.png',
    currencies: ['NGN', 'USD', 'GHS', 'KES'],
    minAmount: 100,
    maxAmount: 5000,
    fee: 1.4,
    feeType: 'percentage',
    enabled: true,
    countries: ['NG', 'GH', 'KE', 'ZA']
  },
  {
    id: 'mpesa',
    name: 'M-Pesa',
    logo: '/mpesa-logo.png',
    currencies: ['KES'],
    minAmount: 10,
    maxAmount: 150000,
    fee: 0.5,
    feeType: 'percentage',
    enabled: true,
    countries: ['KE', 'TZ']
  },
  {
    id: 'revolut',
    name: 'Revolut',
    logo: '/revolut-logo.png',
    currencies: ['EUR', 'GBP', 'USD'],
    minAmount: 100,
    maxAmount: 50000,
    fee: 0.3,
    feeType: 'percentage',
    enabled: true,
    countries: ['EU', 'UK', 'US']
  }
];

// Add dummy data
const DUMMY_TRANSACTIONS: WalletTransaction[] = [
  {
    id: 1,
    date: '2024-03-19',
    amount: 1250.00,
    description: 'Booking.com Commission',
    type: 'credit',
    status: 'completed',
    provider: 'stripe',
    reference: 'TRX-001',
    currency: 'USD',
    fee: 36.25
  },
  {
    id: 2,
    date: '2024-03-18',
    amount: 500.00,
    description: 'Withdrawal to Bank Account',
    type: 'debit',
    status: 'completed',
    provider: 'revolut',
    reference: 'WTH-001',
    currency: 'USD',
    fee: 1.50
  },
  {
    id: 3,
    date: '2024-03-17',
    amount: 750.00,
    description: 'Expedia Commission',
    type: 'credit',
    status: 'completed',
    provider: 'stripe',
    reference: 'TRX-002',
    currency: 'USD',
    fee: 21.75
  },
  {
    id: 4,
    date: '2024-03-16',
    amount: 300.00,
    description: 'M-Pesa Withdrawal',
    type: 'debit',
    status: 'pending',
    provider: 'mpesa',
    reference: 'WTH-002',
    currency: 'KES',
    fee: 1.50
  },
  {
    id: 5,
    date: '2024-03-15',
    amount: 900.00,
    description: 'Airbnb Commission',
    type: 'credit',
    status: 'completed',
    provider: 'stripe',
    reference: 'TRX-003',
    currency: 'USD',
    fee: 26.10
  }
];

// Add dummy pre-sell limit data
const DUMMY_PRESELL_LIMIT: PreSellLimit = {
  total_limit: 1000,
  used_amount: 300,
  available_amount: 700,
  currency: 'USD',
  interest_rate: 0,
  term_days: 365  // Available for use within a year
};

// Add dummy pre-sell offers
const DUMMY_PRESELL_OFFERS: PreSellOffer[] = [
  {
    id: 1,
    room_type: 'Deluxe Double Room',
    room_type_id: 472,
    nights: 1,
    original_price: 100,
    discounted_price: 80,  // 20% discount
    available_rooms: 5,
    start_date: '2024-05-01',
    end_date: '2024-05-02',
    terms: [
      'Valid for 1 year from purchase date',
      'Flexible dates',
      'Subject to availability',
      'Non-refundable purchase'
    ]
  },
  {
    id: 2,
    room_type: 'Superior Single Room',
    room_type_id: 471,
    nights: 1,
    original_price: 80,
    discounted_price: 64,  // 20% discount
    available_rooms: 3,
    start_date: '2024-06-01',
    end_date: '2024-06-02',
    terms: [
      'Valid for 1 year from purchase date',
      'Flexible dates',
      'Subject to availability',
      'Non-refundable purchase'
    ]
  }
];

const Wallet = () => {
  const { hotel } = useHotel();
  const [balance, setBalance] = useState<number>(0);
  const [transactions, setTransactions] = useState<WalletTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<PaymentProvider | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showPresellModal, setShowPresellModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<PreSellOffer | null>(null);
  const [presellLimit, setPresellLimit] = useState<PreSellLimit>(DUMMY_PRESELL_LIMIT);
  const [presellOffers, setPresellOffers] = useState<PreSellOffer[]>(DUMMY_PRESELL_OFFERS);
  const [marketData, setMarketData] = useState<MarketDataItem[]>([]);

  useEffect(() => {
    if (!hotel?.id) return;
    loadWalletData();
  }, [hotel?.id]);

  const loadWalletData = async () => {
    try {
      setLoading(true);
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Calculate balance from dummy transactions
      const calculatedBalance = DUMMY_TRANSACTIONS.reduce((acc, transaction) => {
        if (transaction.status === 'completed') {
          return transaction.type === 'credit' 
            ? acc + transaction.amount 
            : acc - transaction.amount;
        }
        return acc;
      }, 0);

      setBalance(calculatedBalance);
      setTransactions(DUMMY_TRANSACTIONS);
    } catch (err) {
      setError('Failed to load wallet data');
    } finally {
      setLoading(false);
    }
  };

  const calculateFee = (amount: number, provider: PaymentProvider): number => {
    if (provider.feeType === 'percentage') {
      return (amount * provider.fee) / 100;
    }
    return provider.fee;
  };

  const handleWithdraw = async () => {
    if (!selectedProvider || !withdrawAmount) return;

    try {
      setLoading(true);
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      const amount = parseFloat(withdrawAmount);
      const fee = calculateFee(amount, selectedProvider);

      // Create new transaction
      const newTransaction: WalletTransaction = {
        id: Math.max(...transactions.map(t => t.id)) + 1,
        date: new Date().toISOString().split('T')[0],
        amount: amount,
        description: `Withdrawal via ${selectedProvider.name}`,
        type: 'debit',
        status: 'pending',
        provider: selectedProvider.id as WalletTransaction['provider'],
        reference: `WTH-${Math.floor(Math.random() * 1000)}`,
        currency: selectedProvider.currencies[0],
        fee: fee
      };

      // Add new transaction to list
      setTransactions([newTransaction, ...transactions]);
      
      // Update balance
      setBalance(prev => prev - amount - fee);
      
      // Close modal
      setShowWithdrawModal(false);
      setWithdrawAmount('');
      setSelectedProvider(null);
    } catch (err) {
      setError('Withdrawal failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Load market data and generate pre-sell offers
  useEffect(() => {
    if (!hotel?.id) return;

    const loadMarketData = async () => {
      try {
        const response = await fetch(`/api/market-data/${hotel.id}`);
        const data = await response.json();
        setMarketData(data);

        // Generate pre-sell offers from market data
        const uniqueRooms = new Map();
        data.forEach((item: MarketDataItem) => {
          if (!uniqueRooms.has(item.room_type_id)) {
            uniqueRooms.set(item.room_type_id, {
              room_type: item.room_type,
              room_type_id: item.room_type_id,
              price: typeof item.price === 'string' ? parseFloat(item.price) : item.price,
              availability: item.availability
            });
          }
        });

        // Create pre-sell offers from unique rooms
        const offers: PreSellOffer[] = Array.from(uniqueRooms.values())
          .filter(room => room.availability > 0)
          .map((room, index) => ({
            id: index + 1,
            room_type: room.room_type,
            room_type_id: room.room_type_id,
            nights: 1,
            original_price: room.price,
            discounted_price: room.price * 0.5, // 50% discount
            available_rooms: room.availability,
            start_date: new Date().toISOString().split('T')[0],
            end_date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Next day
            terms: [
              'Valid for 1 year from purchase date',
              'Flexible dates',
              'Subject to availability',
              'Non-refundable purchase'
            ]
          }));

        setPresellOffers(offers);
      } catch (error) {
        console.error('Error loading market data:', error);
      }
    };

    loadMarketData();
  }, [hotel?.id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-full flex flex-col overflow-hidden">
      <div className="flex-1 overflow-auto p-6">
        {/* Balance and Pre-sell Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Balance Card */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Available Balance</p>
                <p className="text-3xl font-bold text-gray-900">${balance.toFixed(2)}</p>
              </div>
              <button
                onClick={() => setShowWithdrawModal(true)}
                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                Withdraw Funds
              </button>
            </div>
          </div>

          {/* Pre-sell Limit Card */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Pre-sell Limit</p>
                <p className="text-3xl font-bold text-gray-900">
                  ${presellLimit.available_amount.toFixed(2)}
                </p>
                <p className="text-sm text-gray-500 mt-1">
                  of ${presellLimit.total_limit.toFixed(2)}
                </p>
              </div>
              <button
                onClick={() => setShowPresellModal(true)}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
              >
                Pre-sell Rooms
              </button>
            </div>
            <div className="mt-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-green-600 h-2.5 rounded-full" 
                  style={{ width: `${(presellLimit.used_amount / presellLimit.total_limit) * 100}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500 mt-2">
                Pre-sell your rooms now and use the value anytime within a year
              </p>
            </div>
          </div>
        </div>

        {/* Transactions */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Recent Transactions</h2>
          </div>
          <div className="divide-y divide-gray-200">
            {transactions.map((transaction) => (
              <div key={transaction.id} className="p-6 flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className={`p-2 rounded-full ${
                    transaction.type === 'credit' ? 'bg-green-100' : 'bg-red-100'
                  }`}>
                    {transaction.type === 'credit' ? 
                      <ArrowDownCircle className="h-5 w-5 text-green-600" /> : 
                      <ArrowDownCircle className="h-5 w-5 text-red-600 transform rotate-180" />
                    }
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{transaction.description}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(transaction.date).toLocaleDateString()} • {transaction.provider}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className={`font-medium ${
                    transaction.type === 'credit' ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {transaction.type === 'credit' ? '+' : '-'}${transaction.amount.toFixed(2)}
                  </p>
                  <p className="text-sm text-gray-500">{transaction.status}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Pre-sell Modal */}
      {showPresellModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h3 className="text-xl font-semibold">Pre-sell Rooms</h3>
                <p className="text-sm text-gray-500 mt-1">
                  Sell your rooms now at a discount and receive immediate payment
                </p>
              </div>
              <button
                onClick={() => setShowPresellModal(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                ×
              </button>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h4 className="font-semibold text-blue-900 mb-2">How it works</h4>
              <ul className="text-sm text-blue-800 space-y-2">
                <li>• We buy your rooms at 50% of current rates</li>
                <li>• You receive immediate payment</li>
                <li>• The value can be used anytime within a year</li>
                <li>• Maximum limit of $1,000 USD</li>
                <li>• No interest or additional fees</li>
              </ul>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {presellOffers.map((offer) => (
                <div 
                  key={offer.id}
                  className="border rounded-lg p-4 hover:border-primary cursor-pointer"
                  onClick={() => setSelectedOffer(offer)}
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h4 className="font-semibold">{offer.room_type}</h4>
                      <p className="text-sm text-gray-500">
                        {new Date(offer.start_date).toLocaleDateString()} - {new Date(offer.end_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-lg font-semibold text-green-600">
                        ${offer.discounted_price}
                      </p>
                      <p className="text-sm text-gray-500 line-through">
                        ${offer.original_price}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <span>{offer.nights} nights</span>
                    <span>{offer.available_rooms} rooms available</span>
                  </div>
                  <div className="mt-2">
                    <ul className="text-sm text-gray-600">
                      {offer.terms.map((term: string, index: number) => (
                        <li key={index} className="flex items-center space-x-1">
                          <div className="w-1 h-1 bg-gray-400 rounded-full"></div>
                          <span>{term}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {selectedOffer && (
              <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                <h4 className="font-semibold mb-2">Selected Offer Summary</h4>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="text-gray-500">Room Type</p>
                    <p className="font-medium">{selectedOffer.room_type}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Duration</p>
                    <p className="font-medium">{selectedOffer.nights} nights</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Original Price</p>
                    <p className="font-medium">${selectedOffer.original_price}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Discounted Price</p>
                    <p className="font-medium text-green-600">${selectedOffer.discounted_price}</p>
                  </div>
                </div>
                <button
                  className="w-full mt-4 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                  onClick={() => {
                    // Handle pre-sell confirmation
                    setShowPresellModal(false);
                    setSelectedOffer(null);
                  }}
                >
                  Confirm Pre-sell
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Withdraw Modal */}
      {showWithdrawModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-md p-6">
            <h3 className="text-xl font-semibold mb-4">Withdraw Funds</h3>
            
            {/* Amount Input */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <input
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="Enter amount"
              />
            </div>

            {/* Provider Selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Provider
              </label>
              <div className="grid grid-cols-2 gap-4">
                {PAYMENT_PROVIDERS.map((provider) => (
                  <button
                    key={provider.id}
                    onClick={() => setSelectedProvider(provider)}
                    className={`p-4 border rounded-lg flex items-center space-x-2 ${
                      selectedProvider?.id === provider.id ? 'border-primary ring-1 ring-primary' : ''
                    }`}
                  >
                    <img src={provider.logo} alt={provider.name} className="h-6 w-6" />
                    <span>{provider.name}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Fee Display */}
            {selectedProvider && withdrawAmount && (
              <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                <p className="text-sm text-gray-600">
                  Fee: ${calculateFee(parseFloat(withdrawAmount), selectedProvider).toFixed(2)}
                </p>
                <p className="font-medium">
                  You'll receive: ${(parseFloat(withdrawAmount) - calculateFee(parseFloat(withdrawAmount), selectedProvider)).toFixed(2)}
                </p>
              </div>
            )}

            {/* Actions */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowWithdrawModal(false)}
                className="px-4 py-2 text-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleWithdraw}
                disabled={!selectedProvider || !withdrawAmount}
                className="px-4 py-2 bg-primary text-white rounded-lg disabled:opacity-50"
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet; 