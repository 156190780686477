import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import HotelSelector from '../dashboard/HotelSelector';
import { LogOut, User, Settings, ChevronDown } from 'lucide-react';
import ConfirmationModal from '../common/ConfirmationModal';

const Header = () => {
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleLogoutClick = () => {
    setIsMenuOpen(false);
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = () => {
    setShowLogoutConfirm(false);
    logout();
  };

  return (
    <>
      <header className="bg-white shadow">
        <div className="flex items-center justify-between px-4 py-3">
          <div className="w-64">
            <HotelSelector />
          </div>
          
          <div className="relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="flex items-center space-x-2 p-2 rounded-full bg-primary text-white hover:bg-primary-dark transition-colors"
            >
              <User className="w-5 h-5" />
              <ChevronDown className="w-4 h-4" />
            </button>

            {isMenuOpen && (
              <div 
                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
              >
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={(e) => e.preventDefault()}
                >
                  <Settings className="inline-block w-4 h-4 mr-2" />
                  Profile Settings
                </a>
                <button
                  onClick={handleLogoutClick}
                  className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 focus:outline-none"
                >
                  <LogOut className="inline-block w-4 h-4 mr-2" />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      <ConfirmationModal
        isOpen={showLogoutConfirm}
        onClose={() => setShowLogoutConfirm(false)}
        onConfirm={handleLogoutConfirm}
        title="Confirm Logout"
        message="Are you sure you want to log out? You will need to log in again to access your dashboard."
        confirmText="Yes, Log Out"
        cancelText="Cancel"
      />
    </>
  );
};

export default Header; 