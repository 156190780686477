import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { Guest } from './columns';
import { RadioGroup, RadioGroupItem } from "../../../../components/ui/radio-group";
import { Label } from "../../../../components/ui/label";

interface MergeGuestsModalProps {
  guests: Guest[];
  isOpen: boolean;
  onClose: () => void;
  onMerge: (mergedGuest: Partial<Guest>) => Promise<void>;
}

type GuestField = keyof Omit<Guest, 'id' | 'created_at' | 'bookings'>;

export function MergeGuestsModal({ guests, isOpen, onClose, onMerge }: MergeGuestsModalProps) {
  const [selectedFields, setSelectedFields] = useState<Record<string, number>>({});
  const [isMerging, setIsMerging] = useState(false);

  const fields: Array<{ key: GuestField; label: string }> = [
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name', label: 'Last Name' },
    { key: 'email', label: 'Email' },
    { key: 'phone', label: 'Phone' },
    { key: 'mobile', label: 'Mobile' },
    { key: 'country', label: 'Country' },
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'identity_type', label: 'ID Type' },
    { key: 'identity_number', label: 'ID Number' },
  ];

  const handleMerge = async () => {
    try {
      setIsMerging(true);
      const mergedGuest: Partial<Guest> = {};
      
      // Combine selected fields
      fields.forEach(({ key }) => {
        if (selectedFields[key] !== undefined) {
          const selectedGuest = guests[selectedFields[key]];
          const value = selectedGuest[key];
          if (value !== null) {
            mergedGuest[key] = value;
          }
        }
      });

      // Combine bookings from all guests
      const allBookings = guests.reduce((acc, guest) => {
        return [...acc, ...(guest.bookings || [])];
      }, [] as Guest['bookings']);

      mergedGuest.bookings = allBookings;

      await onMerge(mergedGuest);
      onClose();
    } catch (error) {
      console.error('Error merging guests:', error);
    } finally {
      setIsMerging(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Merge Guest Records</DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <p className="text-sm text-muted-foreground">
            Select which information to keep from each guest record. The merged guest will contain all bookings from both records.
          </p>

          <div className="space-y-4">
            {fields.map(({ key, label }) => (
              <div key={key} className="space-y-2">
                <Label>{label}</Label>
                <RadioGroup
                  value={selectedFields[key]?.toString()}
                  onValueChange={(value) => 
                    setSelectedFields(prev => ({
                      ...prev,
                      [key]: parseInt(value)
                    }))
                  }
                  className="grid grid-cols-2 gap-4"
                >
                  {guests.map((guest, index) => (
                    <div key={index} className="flex items-center space-x-2 border p-4 rounded-lg">
                      <RadioGroupItem value={index.toString()} id={`${key}-${index}`} />
                      <Label htmlFor={`${key}-${index}`} className="flex-grow">
                        {guest[key] || '-'}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            ))}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            onClick={handleMerge} 
            disabled={isMerging || Object.keys(selectedFields).length === 0}
          >
            {isMerging ? 'Merging...' : 'Merge Records'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 