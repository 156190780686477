import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Hotel, TrendingUp, DollarSign, BarChart3, Users2, ArrowRight, CheckCircle2, Building2, Star, BarChart } from 'lucide-react';

const RwandaLandingPage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const caseStudies = [
    {
      name: "Ubumwe Grande Hotel",
      location: "Kigali",
      results: {
        revenueIncrease: "32%",
        timeframe: "6 months",
        occupancyGrowth: "28%",
      },
      image: "https://images.unsplash.com/photo-1582719508461-905c673771fd?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      quote: "HotelOnline's revenue management solutions helped us optimize our pricing strategy and significantly increase our RevPAR."
    },
    {
      name: "Hotel Des Mille Collines",
      location: "Kigali",
      results: {
        revenueIncrease: "45%",
        timeframe: "12 months",
        occupancyGrowth: "35%",
      },
      image: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      quote: "The AI-powered insights combined with local market expertise have transformed our revenue strategy."
    },
    {
      name: "Lake Kivu Serena Hotel",
      location: "Gisenyi",
      results: {
        revenueIncrease: "38%",
        timeframe: "8 months",
        occupancyGrowth: "30%",
      },
      image: "https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      quote: "HotelOnline's platform helped us maximize our revenue during both peak and low seasons."
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-red-700 to-red-900 overflow-hidden">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
        <div className="relative">
          <nav className="container mx-auto px-6 py-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <Hotel className="h-8 w-8 text-white" />
                <span className="text-2xl font-bold text-white">HotelOnline Rwanda</span>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setIsLoginModalOpen(true)}
                  className="text-white hover:text-red-50 transition-colors"
                >
                  Login
                </button>
                <button
                  onClick={() => setIsRegisterModalOpen(true)}
                  className="bg-white text-red-700 px-6 py-2 rounded-lg hover:bg-red-50 transition-colors"
                >
                  Start for Free
                </button>
              </div>
            </div>
          </nav>

          <div className="container mx-auto px-6 py-24 text-center">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 rounded-full text-white mb-8">
              <Star className="h-5 w-5" />
              <span className="font-medium">Rwanda's Leading Hotel Revenue Platform</span>
            </div>
            <h1 className="text-6xl font-bold text-white mb-8 leading-tight">
              Proven Revenue Growth for <br />
              <span className="text-red-300">Rwanda's Top Hotels</span>
            </h1>
            <p className="text-xl text-white/90 mb-12 max-w-3xl mx-auto">
              Join Rwanda's leading hotels in achieving exceptional revenue growth. 
              Our AI-powered platform combined with local expertise delivers results.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Link
                to="/dashboard"
                className="group bg-white text-red-700 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-red-50 transition-all transform hover:scale-105 flex items-center space-x-2"
              >
                <span>Start Growing Revenue</span>
                <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <a
                href="#case-studies"
                className="text-white border-2 border-white/30 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-white/10 transition-all"
              >
                View Success Stories
              </a>
            </div>
            
            {/* Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
              <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
                <div className="text-4xl font-bold text-white mb-2">35%+</div>
                <div className="text-white/80">Average Revenue Growth</div>
              </div>
              <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
                <div className="text-4xl font-bold text-white mb-2">150+</div>
                <div className="text-white/80">Rwanda Hotels Trust Us</div>
              </div>
              <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
                <div className="text-4xl font-bold text-white mb-2">6 Months</div>
                <div className="text-white/80">Average ROI Timeline</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Case Studies Section */}
      <section id="case-studies" className="py-24 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-red-50 rounded-full text-red-700 mb-6">
              <BarChart className="h-5 w-5" />
              <span className="font-medium">Success Stories</span>
            </div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Rwanda Success Stories</h2>
            <p className="text-xl text-gray-600">See how leading Rwanda hotels achieved exceptional results</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
                <img 
                  src={study.image} 
                  alt={study.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{study.name}</h3>
                  <p className="text-gray-600 mb-4">{study.location}</p>
                  
                  <div className="space-y-3 mb-6">
                    <div className="flex items-center justify-between">
                      <span className="text-gray-600">Revenue Growth</span>
                      <span className="font-semibold text-red-700">{study.results.revenueIncrease}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-600">Timeframe</span>
                      <span className="font-semibold text-red-700">{study.results.timeframe}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-600">Occupancy Growth</span>
                      <span className="font-semibold text-red-700">{study.results.occupancyGrowth}</span>
                    </div>
                  </div>
                  
                  <blockquote className="italic text-gray-600 border-l-4 border-red-700 pl-4">
                    "{study.quote}"
                  </blockquote>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-red-50 rounded-full text-red-700 mb-6">
              <TrendingUp className="h-5 w-5" />
              <span className="font-medium">Revenue Solutions</span>
            </div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Tailored for Rwanda's Market</h2>
            <p className="text-xl text-gray-600">Comprehensive solutions designed for Rwanda's unique hospitality landscape</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-red-50 rounded-lg flex items-center justify-center mb-6">
                <BarChart3 className="h-6 w-6 text-red-700" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">Market Intelligence</h3>
              <p className="text-gray-600 mb-4">Local market insights and competitor analysis.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Rwanda market data</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Competitor tracking</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Demand forecasting</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-red-50 rounded-lg flex items-center justify-center mb-6">
                <DollarSign className="h-6 w-6 text-red-700" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">Revenue Optimization</h3>
              <p className="text-gray-600 mb-4">AI-powered pricing and revenue strategies.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Dynamic pricing</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Revenue forecasting</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Occupancy optimization</span>
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-shadow">
              <div className="w-12 h-12 bg-red-50 rounded-lg flex items-center justify-center mb-6">
                <Users2 className="h-6 w-6 text-red-700" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">Local Support</h3>
              <p className="text-gray-600 mb-4">Dedicated team in Rwanda for personalized support.</p>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">24/7 local support</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Training programs</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle2 className="h-5 w-5 text-red-700" />
                  <span className="text-gray-600">Strategy consulting</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-24 bg-gradient-to-br from-red-700 to-red-900 overflow-hidden">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
        <div className="relative container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">Ready to Grow Your Revenue?</h2>
          <p className="text-xl text-white/90 mb-12 max-w-2xl mx-auto">
            Join Rwanda's leading hotels and start maximizing your revenue potential today.
          </p>
          <button
            onClick={() => setIsRegisterModalOpen(true)}
            className="inline-flex items-center space-x-2 bg-white text-red-700 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-red-50 transition-all transform hover:scale-105"
          >
            <span>Start for Free</span>
            <ArrowRight className="h-5 w-5" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default RwandaLandingPage; 