import React from 'react';

interface LogoProps {
  className?: string;
}

const Logo = ({ className = "h-8" }: LogoProps) => {
  return (
    <div className="flex items-center">
      <img 
        src="/hotelonline-logo.png"
        alt="HotelOnline"
        className={`${className} object-contain`}
        style={{ maxWidth: '150px' }}
      />
    </div>
  );
};

export default Logo; 