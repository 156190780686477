"use client";

import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/ui/data-table";
import { GuestColumns, Guest } from "./components/columns";
import { useParams } from "react-router-dom";
import { GuestDetailsModal } from "./components/guest-details-modal";
import { MergeGuestsModal } from "./components/merge-guests-modal";
import { Button } from "../../../components/ui/button";
import { Search, UserPlus, GitMerge } from "lucide-react";

interface Booking {
  BookingID: string;
  StartDate: string;
  EndDate: string;
  Source: string;
  TotalAmount: number;
  RoomTypeName: string;
  CurrentStatus: string;
}

interface GuestWithBookings extends Guest {
  bookings: Booking[];
}

export default function GuestCRM() {
  const [guests, setGuests] = useState<GuestWithBookings[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedGuest, setSelectedGuest] = useState<GuestWithBookings | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState<GuestWithBookings[]>([]);
  const { hotelId } = useParams();

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`/api/hotels/${hotelId}/guests`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch guests');
        }

        const data = await response.json();
        setGuests(data);
      } catch (error) {
        console.error('Error fetching guests:', error);
      } finally {
        setLoading(false);
      }
    };

    if (hotelId) {
      fetchGuests();
    }
  }, [hotelId]);

  const handleRowClick = (guest: GuestWithBookings) => {
    setSelectedGuest(guest);
    setIsDetailsModalOpen(true);
  };

  const handleGuestSelect = (guest: GuestWithBookings, isSelected: boolean) => {
    if (isSelected) {
      setSelectedGuests(prev => [...prev, guest]);
    } else {
      setSelectedGuests(prev => prev.filter(g => g.id !== guest.id));
    }
  };

  const handleMerge = async (mergedGuest: Partial<Guest>) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`/api/hotels/${hotelId}/guests/merge`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guestIds: selectedGuests.map(g => g.id),
          mergedGuest,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to merge guests');
      }

      // Refresh guest list
      const updatedResponse = await fetch(`/api/hotels/${hotelId}/guests`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!updatedResponse.ok) {
        throw new Error('Failed to fetch updated guest list');
      }

      const updatedData = await updatedResponse.json();
      setGuests(updatedData);
      setSelectedGuests([]);
    } catch (error) {
      console.error('Error merging guests:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="flex-col">
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between">
          <h2 className="text-3xl font-bold">Guest Management</h2>
          <div className="flex items-center gap-4">
            <Button
              variant="outline"
              onClick={() => setIsMergeModalOpen(true)}
              disabled={selectedGuests.length < 2}
            >
              <GitMerge className="mr-2 h-4 w-4" />
              Merge Selected ({selectedGuests.length})
            </Button>
            <Button>
              <UserPlus className="mr-2 h-4 w-4" />
              Add Guest
            </Button>
          </div>
        </div>

        <DataTable 
          columns={GuestColumns} 
          data={guests} 
          searchKey="first_name"
          onRowClick={handleRowClick}
          onRowSelect={handleGuestSelect}
        />

        <GuestDetailsModal
          guest={selectedGuest}
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
        />

        {selectedGuests.length >= 2 && (
          <MergeGuestsModal
            guests={selectedGuests}
            isOpen={isMergeModalOpen}
            onClose={() => setIsMergeModalOpen(false)}
            onMerge={handleMerge}
          />
        )}
      </div>
    </div>
  );
} 