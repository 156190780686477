import React, { useCallback, useState, useEffect } from 'react';
import { useHotel } from '../../contexts/HotelContext';
import { useData } from '../../hooks/useData';
import { fetchMarketData } from '../../services/api';
import { Save, X, Plus, RefreshCw, Trash2 } from 'lucide-react';
import MarketPerformance from './MarketPerformance';
import api from '../../api';

interface MarketDataView {
  id?: number;
  name: string;
  settings: {
    selectedRoomTypes: number[];
    unselectedRoomTypes: number[];
  };
  is_default?: boolean;
}

interface MarketDataEntry {
  hotel_id: number;
  hotel_name: string;
  room_type_id: number;
  room_type: string;
  date?: string;
  price?: number | string;
  availability?: number;
  breakfast_info?: string;
  cancellation_policy?: string;
  selected?: boolean;
}

interface DateData {
  price?: string | number;
  availability?: number;
}

interface RoomData {
  hotel_name: string;
  room_type: string;
  room_type_id: number;
  lowestPrice?: number;
  dates: Map<string, DateData>;
}

interface SortConfig {
  key: 'hotel_name' | 'date';
  date?: string;
  direction: 'asc' | 'desc';
}

const MarketDataComponent = () => {
  const { hotel } = useHotel();
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [selectedRoomTypes, setSelectedRoomTypes] = useState<Set<number>>(new Set());
  const [views, setViews] = useState<MarketDataView[]>([]);
  const [currentView, setCurrentView] = useState<MarketDataView | null>(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [newViewName, setNewViewName] = useState('');
  const [unselectedRoomTypes, setUnselectedRoomTypes] = useState<Set<number>>(new Set());
  const [allRoomTypes, setAllRoomTypes] = useState<number[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editViewName, setEditViewName] = useState('');

  const fetchData = useCallback(async () => {
    if (!hotel?.id) {
      console.log('Waiting for hotel ID...');
      return [];
    }
    
    try {
      console.log('Fetching market data for hotel:', hotel.id);
      const response = await fetchMarketData(Number(hotel.id));
      
      if (!response || !Array.isArray(response)) {
        console.error('Invalid market data format:', response);
        return [];
      }

      console.log('Received market data:', {
        totalEntries: response.length,
        uniqueRoomTypes: new Set(response.map(item => item.room_type_id)).size,
        sampleEntry: response[0]
      });

      return response;
    } catch (error) {
      console.error('Error fetching market data:', error);
      return [];
    }
  }, [hotel?.id]);

  const { data: marketData = [], loading, error } = useData<MarketDataEntry[]>(fetchData);

  useEffect(() => {
    if (marketData && marketData.length > 0) {
      const allRoomTypes = new Set(marketData.map(item => item.room_type_id));
      setSelectedRoomTypes(allRoomTypes);
    }
  }, [marketData]);

  useEffect(() => {
    if (hotel?.id) {
      fetchViews();
    }
  }, [hotel]);

  const fetchViews = async () => {
    try {
      if (!hotel?.id) {
        console.log('No hotel ID available');
        setViews([]);
        return;
      }

      console.log('Fetching views for hotel:', hotel.id);
      
      const response = await api.get(`/api/market-data-views/${hotel.id}`);
      console.log('Fetched views:', response.data);
      setViews(Array.isArray(response.data) ? response.data : []);

    } catch (error) {
      console.error('Error fetching views:', error);
      setViews([]);
    }
  };

  const handleRoomTypeToggle = (roomTypeId: number) => {
    const newSelected = new Set(selectedRoomTypes);
    const newUnselected = new Set(unselectedRoomTypes);

    if (unselectedRoomTypes.has(roomTypeId)) {
      newUnselected.delete(roomTypeId);
      newSelected.add(roomTypeId);
    } else {
      newSelected.delete(roomTypeId);
      newUnselected.add(roomTypeId);
    }

    setSelectedRoomTypes(newSelected);
    setUnselectedRoomTypes(newUnselected);

    if (currentView?.id) {
      saveViewSettings(currentView.id, {
        selectedRoomTypes: Array.from(newSelected),
        unselectedRoomTypes: Array.from(newUnselected)
      });
    }
  };

  const saveViewSettings = async (viewId: number, settings: MarketDataView['settings']) => {
    try {
      await api.put(`/api/market-data-views/${viewId}`, {
        settings: settings
      });
    } catch (error) {
      console.error('Error saving view settings:', error);
    }
  };

  const loadView = (view: MarketDataView) => {
    setCurrentView(view);
    if (view.settings?.selectedRoomTypes) {
      setSelectedRoomTypes(new Set(view.settings.selectedRoomTypes));
    }
    if (view.settings?.unselectedRoomTypes) {
      setUnselectedRoomTypes(new Set(view.settings.unselectedRoomTypes));
    }
  };

  const saveView = async () => {
    if (!newViewName.trim() || !hotel?.id) return;

    if (views.some(view => view.name.toLowerCase() === newViewName.toLowerCase())) {
      alert('A view with this name already exists. Please choose a different name.');
      return;
    }

    const viewData: MarketDataView = {
      name: newViewName,
      settings: {
        selectedRoomTypes: Array.from(selectedRoomTypes),
        unselectedRoomTypes: Array.from(unselectedRoomTypes)
      }
    };

    try {
      console.log('Saving view with data:', viewData);
      const response = await api.post(`/api/market-data-views/${hotel.id}`, viewData);
      
      if (response.data) {
        console.log('View saved successfully:', response.data);
        setViews(prev => [...prev, response.data]);
        setCurrentView(response.data);
        setShowViewModal(false);
        setNewViewName('');
      }
    } catch (error: any) {
      console.error('Error saving view:', error);
      if (error?.response?.status === 409) {
        alert('A view with this name already exists. Please choose a different name.');
      }
    }
  };

  const processedDataByRoom = React.useMemo(() => {
    if (!marketData || !Array.isArray(marketData)) {
      return new Map();
    }
    
    const dataByRoom = new Map<string, {
      hotel_name: string;
      room_type: string;
      room_type_id: number;
      lowestPrice?: number;
      dates: Map<string, { price?: string | number; availability?: number }>
    }>();
    
    marketData.forEach(item => {
      if (!item?.date) return;
      
      const key = `${item.hotel_id}-${item.room_type_id}`;
      if (!dataByRoom.has(key)) {
        dataByRoom.set(key, {
          hotel_name: item.hotel_name,
          room_type: item.room_type,
          room_type_id: item.room_type_id,
          dates: new Map(),
          lowestPrice: undefined
        });
      }
      
      const roomData = dataByRoom.get(key);
      if (roomData) {
        // Convert price to number immediately
        const price = typeof item.price === 'string' ? parseFloat(item.price) : 
                     typeof item.price === 'number' ? item.price : undefined;
        
        roomData.dates.set(item.date, {
          price,
          availability: item.availability
        });

        // Update lowest price if this price is valid and lower
        if (typeof price === 'number' && (roomData.lowestPrice === undefined || price < roomData.lowestPrice)) {
          roomData.lowestPrice = price;
        }
      }
    });

    return dataByRoom;
  }, [marketData]);

  useEffect(() => {
    if (allRoomTypes.length > 0 && selectedRoomTypes.size === 0 && unselectedRoomTypes.size === 0) {
      setSelectedRoomTypes(new Set(allRoomTypes));
    }
  }, [allRoomTypes]);

  const dates = React.useMemo(() => {
    if (!marketData) return [];
    return Array.from(new Set(marketData.map(item => item.date).filter(Boolean))).sort();
  }, [marketData]);

  const filteredData = React.useMemo(() => {
    return Array.from(processedDataByRoom.values()).filter(room => {
      return selectedRoomTypes.has(room.room_type_id) && 
             !unselectedRoomTypes.has(room.room_type_id);
    });
  }, [processedDataByRoom, selectedRoomTypes, unselectedRoomTypes]);

  const sortedRoomData = React.useMemo(() => {
    const sorted = [...filteredData];
    
    if (!sortConfig) {
        return sorted.sort((a, b) => a.hotel_name.localeCompare(b.hotel_name));
    }
    
    return sorted.sort((a, b) => {
        if (sortConfig.key === 'hotel_name') {
            // Handle empty hotel names
            if (!a.hotel_name && !b.hotel_name) return 0;
            if (!a.hotel_name) return 1; // Sort empty names last
            if (!b.hotel_name) return -1;
            
            const comparison = a.hotel_name.localeCompare(b.hotel_name);
            return sortConfig.direction === 'asc' ? comparison : -comparison;
        }
        
        if (sortConfig.key === 'date' && sortConfig.date) {
            const aPrice = a.dates.get(sortConfig.date)?.price;
            const bPrice = b.dates.get(sortConfig.date)?.price;
            
            // Handle empty/undefined prices
            if (!aPrice && !bPrice) return 0;
            if (!aPrice) return 1; // Sort empty prices last
            if (!bPrice) return -1;
            
            // Convert prices to numbers
            const aValue = typeof aPrice === 'string' ? parseFloat(aPrice) : aPrice;
            const bValue = typeof bPrice === 'string' ? parseFloat(bPrice) : bPrice;
            
            const comparison = aValue - bValue;
            return sortConfig.direction === 'asc' ? comparison : -comparison;
        }
        
        return 0;
    });
  }, [filteredData, sortConfig]);

  const handleSort = (key: 'hotel_name' | 'date', date?: string) => {
    setSortConfig(current => {
        if (current?.key === key && current?.date === date) {
            return {
                key,
                date,
                direction: current.direction === 'asc' ? 'desc' : 'asc'
            };
        }
        
        return {
            key,
            date,
            direction: 'asc'
        };
    });
  };

  console.log('Component market data:', {
    length: marketData?.length || 0,
    dates: marketData?.map(item => item.date).filter(Boolean).slice(0, 5) || [],
    sample: marketData?.slice(0, 2) || []
  });

  // Process data for MarketPerformance
  const marketPerformanceData = React.useMemo(() => {
    if (!marketData || !Array.isArray(marketData) || marketData.length === 0) return [];
    
    try {
      const hotelData = Array.from(processedDataByRoom.values()).map((roomData: RoomData) => ({
        hotelName: roomData.hotel_name || 'Unknown Hotel',
        prices: Object.fromEntries(
          Array.from(roomData.dates || new Map()).map(([date, data]) => [
            date,
            {
              price: typeof data?.price === 'string' ? parseFloat(data.price) : (data?.price || 0),
              availability: data?.availability || 0
            }
          ])
        )
      }));

      return hotelData;
    } catch (error) {
      console.error('Error processing market performance data:', error);
      return [];
    }
  }, [marketData, processedDataByRoom]);

  const LoadingState = () => (
    <div className="flex items-center justify-center h-64">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      <span className="ml-2">Loading market data...</span>
    </div>
  );

  const ErrorState = ({ error }: { error: string }) => (
    <div className="flex items-center justify-center h-64 text-red-500">
      <span>Error loading market data: {error}</span>
    </div>
  );

  const deleteView = async (viewId: number) => {
    try {
      await api.delete(`/api/market-data-views/${viewId}`);
      setViews(prev => prev.filter(view => view.id !== viewId));
      if (currentView?.id === viewId) {
        setCurrentView(null);
        setSelectedRoomTypes(new Set(allRoomTypes));
      }
    } catch (error) {
      console.error('Error deleting view:', error);
      alert('Failed to delete view');
    }
  };

  const updateView = async (viewId: number, name: string) => {
    try {
      const settings = {
        selectedRoomTypes: Array.from(selectedRoomTypes),
        unselectedRoomTypes: Array.from(unselectedRoomTypes)
      };

      await api.put(`/api/market-data-views/${viewId}`, { name, settings });
      setViews(prev => prev.map(view => 
        view.id === viewId 
          ? { ...view, name, settings }
          : view
      ));
    } catch (error: any) {
      console.error('Error updating view:', error);
      if (error?.response?.status === 409) {
        alert('A view with this name already exists');
      } else {
        alert('Failed to update view');
      }
    }
  };

  if (!hotel?.id) {
    return <div className="p-4">Please select a hotel to view market data.</div>;
  }

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  if (!marketData || marketData.length === 0) {
    return (
      <div className="p-4">
        <div className="text-center text-gray-500">
          <p>No market data available for this hotel.</p>
          <p className="text-sm mt-2">Try selecting a different date range or check back later.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-4">
      {/* Market Performance Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <MarketPerformance data={marketPerformanceData} />
      </div>

      {/* Views Controls */}
      <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100">
        <div className="flex items-center space-x-2">
          <select 
            className="px-3 py-1.5 border rounded-md text-sm bg-white"
            value={currentView?.id || ''}
            onChange={(e) => {
              const view = views.find(v => v.id === parseInt(e.target.value));
              if (view) loadView(view);
            }}
          >
            <option value="">Select View</option>
            {views.map(view => (
              <option key={view.id} value={view.id}>{view.name}</option>
            ))}
          </select>

          <button
            onClick={() => setShowViewModal(true)}
            className="px-3 py-1.5 bg-blue-600 text-white rounded-md text-sm inline-flex items-center hover:bg-blue-700"
          >
            <Plus className="w-4 h-4 mr-1" />
            New View
          </button>
        </div>
      </div>

      {/* Market Data Table */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="w-6 px-2 py-2">
                  <input type="checkbox" className="rounded border-gray-300 w-4 h-4" />
                </th>
                <th 
                  onClick={() => handleSort('hotel_name')}
                  className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                >
                  Hotel {sortConfig?.key === 'hotel_name' && (
                    <span className="ml-1 text-gray-400">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                  )}
                </th>
                <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Room Type
                </th>
                {dates.map(date => (
                  <th 
                    key={date}
                    onClick={() => handleSort('date', date)}
                    className="px-2 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                  >
                    {date && new Date(date).toLocaleDateString('en-US', { 
                      month: 'short', 
                      day: 'numeric'
                    })}
                    {sortConfig?.key === 'date' && sortConfig.date === date && (
                      <span className="ml-1 text-gray-400">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {sortedRoomData.map((roomData, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-2 py-1.5 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={!unselectedRoomTypes.has(roomData.room_type_id)}
                      onChange={() => handleRoomTypeToggle(roomData.room_type_id)}
                      className="rounded border-gray-300 w-4 h-4"
                    />
                  </td>
                  <td className="px-2 py-1.5 whitespace-nowrap">
                    <div className="text-sm text-gray-900 truncate max-w-[200px]" title={roomData.hotel_name}>
                      {roomData.hotel_name}
                    </div>
                  </td>
                  <td className="px-2 py-1.5 whitespace-nowrap">
                    <div className="text-sm text-gray-500 truncate max-w-[150px]" title={roomData.room_type}>
                      {roomData.room_type}
                    </div>
                  </td>
                  {dates.map(date => {
                    const dateData = roomData.dates.get(date);
                    const price = dateData?.price;
                    const isLowestPrice = !sortConfig && price && 
                      parseFloat(price.toString()) === roomData.lowestPrice;

                    return (
                      <td key={date} className="px-2 py-1.5 whitespace-nowrap text-center">
                        <div className={`text-sm font-medium ${isLowestPrice ? 'text-green-600' : 'text-gray-900'}`}>
                          {price ? `$${typeof price === 'string' ? parseFloat(price).toFixed(2) : price.toFixed(2)}` : '-'}
                        </div>
                        <div className="text-xs text-gray-400">
                          {dateData?.availability ? `${dateData.availability} left` : 'N/A'}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showViewModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h3 className="text-lg font-medium mb-4">Save View</h3>
            <input
              type="text"
              value={newViewName}
              onChange={(e) => setNewViewName(e.target.value)}
              placeholder="View Name"
              className="w-full px-3 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowViewModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={saveView}
                className="px-4 py-2 bg-blue-600 text-white rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showEditModal && currentView && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h3 className="text-lg font-medium mb-4">Edit View</h3>
            <input
              type="text"
              value={editViewName}
              onChange={(e) => setEditViewName(e.target.value)}
              placeholder="View Name"
              className="w-full px-3 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setShowEditModal(false);
                  setEditViewName('');
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (currentView.id) {
                    updateView(currentView.id, editViewName);
                    setShowEditModal(false);
                    setEditViewName('');
                  }
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketDataComponent;
