import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  DollarSign, Users, Zap, Trophy,
  BarChart3, Calendar, Briefcase, Clock, X, Check,
  ChevronUp, ChevronDown, Dot, Table2, Filter,
  Coffee, Ban, CheckCircle2, Globe, ShoppingCart, Brain,
  TrendingUp, Star, User, Building2, ArrowUpRight, Award
} from 'lucide-react';
import { fetchHotelRankings, fetchReviewTrends } from '../../services/reviewApi';
import { useData } from '../../hooks/useData';
import { MarketData, MarketMetrics, ReviewMetrics } from '../../types/market';
import { useHotel } from '../../contexts/HotelContext';

// Import the Hotel type from types file
import type { Hotel, ReviewTrend, HotelRanking } from '../../types';

interface MarketDataEntry {
  hotel_name: string;
  room_type: string;
  price: number;
  availability: number;
  breakfast_included: boolean;
  cancellation_policy: string;
  last_updated: string;
  [key: string]: any;
}

const MarketInsightCard = ({ hotel }: { hotel: Hotel | null }) => {
  const [showFullView, setShowFullView] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof MarketDataEntry;
    direction: 'asc' | 'desc';
  }>({ key: 'price', direction: 'asc' });
  const [filters, setFilters] = useState({
    roomType: 'all',
    priceRange: { min: 0, max: 1000 },
    breakfast: 'all',
    cancellation: 'all'
  });

  // Dummy market data for presentation
  const marketData: MarketDataEntry[] = [
    { 
      hotel_name: "Grand Plaza Hotel", 
      room_type: "Deluxe Room", 
      price: 189, 
      availability: 5,
      breakfast_included: true,
      cancellation_policy: "Free cancellation",
      last_updated: "2 mins ago"
    },
    { 
      hotel_name: "Riverside Inn", 
      room_type: "Standard Room", 
      price: 145, 
      availability: 3,
      breakfast_included: false,
      cancellation_policy: "Non-refundable",
      last_updated: "5 mins ago"
    },
    { 
      hotel_name: "The Continental", 
      room_type: "Suite", 
      price: 299, 
      availability: 2,
      breakfast_included: true,
      cancellation_policy: "Free cancellation",
      last_updated: "1 min ago"
    },
    { 
      hotel_name: "City Lights Hotel", 
      room_type: "Deluxe Room", 
      price: 175, 
      availability: 7,
      breakfast_included: true,
      cancellation_policy: "24h cancellation",
      last_updated: "Just now"
    },
    { 
      hotel_name: "Ocean View Resort", 
      room_type: "Premium Room", 
      price: 225, 
      availability: 4,
      breakfast_included: true,
      cancellation_policy: "Free cancellation",
      last_updated: "3 mins ago"
    },
    { 
      hotel_name: "Mountain Lodge", 
      room_type: "Standard Room", 
      price: 155, 
      availability: 6,
      breakfast_included: false,
      cancellation_policy: "Non-refundable",
      last_updated: "7 mins ago"
    },
  ];

  const filteredData = marketData.filter(item => {
    if (filters.roomType !== 'all' && item.room_type !== filters.roomType) return false;
    if (item.price < filters.priceRange.min || item.price > filters.priceRange.max) return false;
    if (filters.breakfast !== 'all' && item.breakfast_included !== (filters.breakfast === 'yes')) return false;
    if (filters.cancellation !== 'all' && item.cancellation_policy !== filters.cancellation) return false;
    return true;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  });

  const handleSort = (key: keyof MarketDataEntry) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const SortIcon = ({ active, direction }: { active: boolean; direction: 'asc' | 'desc' }) => (
    <div className={`inline-flex flex-col ml-1 ${active ? 'text-blue-600' : 'text-gray-400'}`}>
      <ChevronUp className={`h-3 w-3 ${direction === 'asc' && active ? 'text-blue-600' : ''}`} />
      <ChevronDown className={`h-3 w-3 -mt-1 ${direction === 'desc' && active ? 'text-blue-600' : ''}`} />
    </div>
  );

  const uniqueRoomTypes = Array.from(new Set(marketData.map(item => item.room_type)));
  const uniqueCancellationPolicies = Array.from(new Set(marketData.map(item => item.cancellation_policy)));

  return (
    <>
      <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-5 border border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Building2 className="h-5 w-5 text-blue-600" />
            </div>
            <h3 className="font-semibold text-gray-900">Market Data</h3>
          </div>
          <button
            onClick={() => setShowFullView(true)}
            className="text-sm text-blue-600 font-medium flex items-center gap-1 hover:text-blue-700"
          >
            <Table2 className="h-4 w-4" />
            View Full Table
          </button>
        </div>

        {/* Preview Table */}
        <div className="overflow-x-auto">
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-200">
                <th className="text-left py-2 font-medium text-gray-600">Property</th>
                <th className="text-left py-2 font-medium text-gray-600">Room Type</th>
                <th className="text-right py-2 font-medium text-gray-600">Price</th>
                <th className="text-right py-2 font-medium text-gray-600">Avail.</th>
              </tr>
            </thead>
            <tbody>
              {sortedData.slice(0, 3).map((item, index) => (
                <tr key={index} className="border-b border-gray-100 last:border-0">
                  <td className="py-2">{item.hotel_name}</td>
                  <td className="py-2">{item.room_type}</td>
                  <td className="py-2 text-right">${item.price}</td>
                  <td className="py-2 text-right">{item.availability}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 pt-4 border-t border-gray-100">
          <Link 
            to={`/dashboard/${hotel?.id}/market`}
            className="flex items-center justify-between text-sm text-blue-600 hover:text-blue-700 font-medium"
          >
            View Full Market Analysis
            <ArrowUpRight className="h-4 w-4" />
          </Link>
        </div>
      </div>

      {/* Full Table Modal */}
      {showFullView && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl max-w-6xl w-full max-h-[90vh] overflow-hidden">
            <div className="p-6 border-b border-gray-200 flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Market Data Analysis</h2>
                <p className="text-sm text-gray-500 mt-1">Compare rates and availability across your market</p>
              </div>
              <button 
                onClick={() => setShowFullView(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {/* Filters */}
            <div className="p-4 border-b border-gray-200 bg-gray-50">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Filter className="h-4 w-4 text-gray-500" />
                  <span className="text-sm font-medium text-gray-700">Filters:</span>
                </div>
                
                {/* Room Type Filter */}
                <select 
                  className="text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                  value={filters.roomType}
                  onChange={(e) => setFilters(prev => ({ ...prev, roomType: e.target.value }))}
                >
                  <option value="all">All Room Types</option>
                  {uniqueRoomTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>

                {/* Price Range Filter */}
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    placeholder="Min $"
                    className="w-20 text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                    value={filters.priceRange.min}
                    onChange={(e) => setFilters(prev => ({ 
                      ...prev, 
                      priceRange: { ...prev.priceRange, min: Number(e.target.value) }
                    }))}
                  />
                  <span className="text-gray-400">-</span>
                  <input
                    type="number"
                    placeholder="Max $"
                    className="w-20 text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                    value={filters.priceRange.max}
                    onChange={(e) => setFilters(prev => ({ 
                      ...prev, 
                      priceRange: { ...prev.priceRange, max: Number(e.target.value) }
                    }))}
                  />
                </div>

                {/* Breakfast Filter */}
                <select 
                  className="text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                  value={filters.breakfast}
                  onChange={(e) => setFilters(prev => ({ ...prev, breakfast: e.target.value }))}
                >
                  <option value="all">All Breakfast Options</option>
                  <option value="yes">With Breakfast</option>
                  <option value="no">Without Breakfast</option>
                </select>

                {/* Cancellation Policy Filter */}
                <select 
                  className="text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                  value={filters.cancellation}
                  onChange={(e) => setFilters(prev => ({ ...prev, cancellation: e.target.value }))}
                >
                  <option value="all">All Cancellation Policies</option>
                  {uniqueCancellationPolicies.map(policy => (
                    <option key={policy} value={policy}>{policy}</option>
                  ))}
                </select>

                {/* Date Selector */}
                <input
                  type="date"
                  className="text-sm border border-gray-200 rounded-lg px-3 py-1.5"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
            </div>

            <div className="p-6 overflow-x-auto">
              <table className="w-full text-sm">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th 
                      className="text-left py-3 px-4 font-medium text-gray-600 cursor-pointer hover:bg-gray-50"
                      onClick={() => handleSort('hotel_name')}
                    >
                      <div className="flex items-center">
                        Property
                        <SortIcon 
                          active={sortConfig.key === 'hotel_name'} 
                          direction={sortConfig.direction} 
                        />
                      </div>
                    </th>
                    <th 
                      className="text-left py-3 px-4 font-medium text-gray-600 cursor-pointer hover:bg-gray-50"
                      onClick={() => handleSort('room_type')}
                    >
                      <div className="flex items-center">
                        Room Type
                        <SortIcon 
                          active={sortConfig.key === 'room_type'} 
                          direction={sortConfig.direction} 
                        />
                      </div>
                    </th>
                    <th 
                      className="text-right py-3 px-4 font-medium text-gray-600 cursor-pointer hover:bg-gray-50"
                      onClick={() => handleSort('price')}
                    >
                      <div className="flex items-center justify-end">
                        Price
                        <SortIcon 
                          active={sortConfig.key === 'price'} 
                          direction={sortConfig.direction} 
                        />
                      </div>
                    </th>
                    <th 
                      className="text-right py-3 px-4 font-medium text-gray-600 cursor-pointer hover:bg-gray-50"
                      onClick={() => handleSort('availability')}
                    >
                      <div className="flex items-center justify-end">
                        Availability
                        <SortIcon 
                          active={sortConfig.key === 'availability'} 
                          direction={sortConfig.direction} 
                        />
                      </div>
                    </th>
                    <th className="text-center py-3 px-4 font-medium text-gray-600">Breakfast</th>
                    <th className="text-left py-3 px-4 font-medium text-gray-600">Cancellation</th>
                    <th className="text-right py-3 px-4 font-medium text-gray-600">Last Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((item, index) => (
                    <tr 
                      key={index} 
                      className="border-b border-gray-100 last:border-0 hover:bg-gray-50"
                    >
                      <td className="py-3 px-4">{item.hotel_name}</td>
                      <td className="py-3 px-4">{item.room_type}</td>
                      <td className="py-3 px-4 text-right font-medium">${item.price}</td>
                      <td className="py-3 px-4 text-right">
                        <span className={`inline-flex items-center gap-1 ${
                          item.availability <= 2 ? 'text-red-600' : 
                          item.availability <= 5 ? 'text-yellow-600' : 
                          'text-green-600'
                        }`}>
                          {item.availability}
                          <Dot className="h-4 w-4" />
                        </span>
                      </td>
                      <td className="py-3 px-4 text-center">
                        {item.breakfast_included ? (
                          <Coffee className="h-4 w-4 text-green-500 inline" />
                        ) : (
                          <Ban className="h-4 w-4 text-gray-300 inline" />
                        )}
                      </td>
                      <td className="py-3 px-4">
                        <span className={`text-sm ${
                          item.cancellation_policy === 'Free cancellation' ? 'text-green-600' :
                          item.cancellation_policy === 'Non-refundable' ? 'text-red-600' :
                          'text-yellow-600'
                        }`}>
                          {item.cancellation_policy}
                        </span>
                      </td>
                      <td className="py-3 px-4 text-right text-gray-500">{item.last_updated}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="p-6 bg-gray-50 border-t border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <Dot className="h-4 w-4 text-green-600" />
                    High Availability
                  </div>
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <Dot className="h-4 w-4 text-yellow-600" />
                    Limited
                  </div>
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <Dot className="h-4 w-4 text-red-600" />
                    Almost Full
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  Last updated: {new Date().toLocaleTimeString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ReviewsCard = ({ hotel }: { hotel: Hotel | null }) => {
  const [showModal, setShowModal] = useState(false);

  // Fallback data for presentation
  const reviewMetrics = {
    review_score: hotel?.review_score || 4.7,
    number_of_reviews: hotel?.number_of_reviews || 856,
    recent_reviews: 42,
    positive_percentage: 89,
    service_rating: 4.2,
    categories: {
      cleanliness: { score: 4.8, count: 820 },
      location: { score: 4.9, count: 840 },
      service: { score: 4.7, count: 815 },
      value: { score: 4.5, count: 800 }
    }
  };

  const reviewsData = [
    {
      author: "James Wilson",
      rating: 5,
      date: "2 days ago",
      comment: "Outstanding hotel experience! The staff went above and beyond, rooms were immaculate, and the breakfast was exceptional.",
      response: "Thank you James for your wonderful review! We're delighted to hear about your experience."
    },
    {
      author: "Emma Thompson",
      rating: 4,
      date: "1 week ago",
      comment: "Beautiful property with excellent amenities. The spa service was fantastic. Minor issue with room service timing.",
      response: "Thank you Emma for your feedback. We're addressing the room service timing to ensure better service."
    },
    {
      author: "David Chen",
      rating: 5,
      date: "2 weeks ago",
      comment: "Perfect business stay. Great conference facilities, reliable WiFi, and the executive lounge was a nice touch.",
      response: "We appreciate your feedback David! Glad to hear our business amenities met your needs."
    },
    {
      author: "Maria Garcia",
      rating: 4,
      date: "3 weeks ago",
      comment: "Lovely stay overall. The rooftop restaurant was amazing and the views were spectacular.",
    }
  ];

  return (
    <>
      <div 
        onClick={() => setShowModal(true)}
        className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-5 cursor-pointer"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-yellow-50 rounded-lg">
              <Star className="h-5 w-5 text-yellow-500" />
            </div>
            <h3 className="font-semibold text-gray-900">Guest Reviews</h3>
          </div>
          <span className="text-sm text-gray-500">Last 30 days</span>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Overall Rating</span>
            <div className="flex items-center gap-1">
              <span className="font-semibold">{reviewMetrics.review_score}</span>
              <Star className="h-4 w-4 text-yellow-400 fill-current" />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Total Reviews</span>
            <span className="font-semibold">{reviewMetrics.number_of_reviews}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Recent Reviews</span>
            <span className="font-semibold text-green-600">+{reviewMetrics.recent_reviews}</span>
          </div>
        </div>
      </div>

      {/* Reviews Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="relative">
              <div className="sticky top-0 bg-white border-b border-gray-100 p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-2xl font-bold text-gray-900">Guest Reviews & Feedback</h2>
                  <button 
                    onClick={() => setShowModal(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
                
                {/* Overall Stats */}
                <div className="grid grid-cols-4 gap-4 mb-6">
                  <div className="bg-yellow-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-yellow-700 mb-1">
                      {reviewMetrics.review_score}
                    </div>
                    <div className="text-sm text-yellow-600">Overall Rating</div>
                  </div>
                  <div className="bg-blue-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-blue-700 mb-1">
                      {reviewMetrics.number_of_reviews}
                    </div>
                    <div className="text-sm text-blue-600">Total Reviews</div>
                  </div>
                  <div className="bg-green-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-green-700 mb-1">{reviewMetrics.positive_percentage}%</div>
                    <div className="text-sm text-green-600">Positive</div>
                  </div>
                  <div className="bg-purple-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-purple-700 mb-1">{reviewMetrics.service_rating}</div>
                    <div className="text-sm text-purple-600">Service Rating</div>
                  </div>
                </div>

                {/* Rating Breakdown */}
                <div className="space-y-3">
                  {Object.entries(reviewMetrics.categories).map(([category, data]) => (
                    <div key={category} className="flex items-center gap-4">
                      <div className="w-24 text-sm text-gray-600 capitalize">{category}</div>
                      <div className="flex-1">
                        <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                          <div 
                            className="h-full bg-yellow-400 rounded-full"
                            style={{ width: `${(data.score / 5) * 100}%` }}
                          />
                        </div>
                      </div>
                      <div className="w-16 text-sm font-medium text-gray-900">
                        {data.score.toFixed(1)}
                      </div>
                      <div className="w-16 text-sm text-gray-500">
                        ({data.count})
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Recent Reviews */}
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Recent Reviews</h3>
                <div className="space-y-6">
                  {reviewsData.map((review, index) => (
                    <div key={index} className="border-b border-gray-100 last:border-0 pb-6 last:pb-0">
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                          <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
                            <User className="h-4 w-4 text-gray-500" />
                          </div>
                          <span className="font-medium text-gray-900">{review.author}</span>
                        </div>
                        <span className="text-sm text-gray-500">{review.date}</span>
                      </div>
                      <div className="flex items-center gap-1 mb-2">
                        {Array.from({ length: 5 }).map((_, i) => (
                          <Star 
                            key={i}
                            className={`h-4 w-4 ${i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-200'}`}
                          />
                        ))}
                      </div>
                      <p className="text-gray-600 mb-3">{review.comment}</p>
                      {review.response && (
                        <div className="bg-gray-50 rounded-lg p-3">
                          <div className="flex items-center gap-2 mb-2">
                            <Building2 className="h-4 w-4 text-blue-500" />
                            <span className="text-sm font-medium text-blue-600">Hotel Response</span>
                          </div>
                          <p className="text-sm text-gray-600">{review.response}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Review Trends */}
              <div className="p-6 bg-gray-50">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Review Trends</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-600 mb-3">Rating Distribution</h4>
                    {[5, 4, 3, 2, 1].map((rating) => (
                      <div key={rating} className="flex items-center gap-2 mb-2">
                        <div className="w-8 text-sm text-gray-600">{rating}★</div>
                        <div className="flex-1">
                          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                            <div 
                              className="h-full bg-yellow-400 rounded-full"
                              style={{ width: `${Math.random() * 100}%` }}
                            />
                          </div>
                        </div>
                        <div className="w-12 text-sm text-gray-500">
                          {Math.floor(Math.random() * 100)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-white p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-600 mb-3">Monthly Trend</h4>
                    <div className="h-40 flex items-end justify-between gap-2">
                      {Array.from({ length: 6 }).map((_, i) => (
                        <div key={i} className="flex-1">
                          <div 
                            className="bg-blue-400 rounded-t-sm w-full"
                            style={{ height: `${Math.random() * 100}%` }}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-between mt-2 text-xs text-gray-500">
                      <span>Jun</span>
                      <span>Jul</span>
                      <span>Aug</span>
                      <span>Sep</span>
                      <span>Oct</span>
                      <span>Nov</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RevenueOpportunityCard = ({ hotel }: { hotel: Hotel | null }) => {
  const [showModal, setShowModal] = useState(false);
  
  return (
    <>
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-5">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
              <DollarSign className="h-5 w-5 text-white" />
            </div>
            <div>
              <h3 className="font-semibold text-white">Join Connect</h3>
              <p className="text-sm text-blue-100">Your Personal Revenue Manager</p>
            </div>
          </div>
          <span className="text-xs px-2 py-1 bg-white/10 rounded-full backdrop-blur-sm text-white">Premium</span>
        </div>

        <p className="text-sm text-blue-50 mb-4">
          Get your dedicated Revenue Manager and unlock guaranteed revenue growth with our all-in-one solution.
        </p>

        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-sm text-blue-50">
            <Users className="h-4 w-4" />
            <span>Personal Revenue Expert</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-blue-50">
            <Globe className="h-4 w-4" />
            <span>Global Distribution</span>
          </div>
        </div>

        <button 
          onClick={() => setShowModal(true)}
          className="w-full py-2 bg-white text-blue-600 rounded-lg font-medium text-sm hover:bg-blue-50 transition-colors"
        >
          Revenue Growth Guarantee
        </button>
      </div>

      {/* Revenue Manager Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="relative">
              {/* Hero Section with Image */}
              <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-t-xl overflow-hidden">
                <div className="absolute top-4 right-4 z-10">
                  <button 
                    onClick={() => setShowModal(false)}
                    className="text-white/80 hover:text-white"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
                <div className="p-8 flex gap-8 items-center">
                  <div className="flex-1">
                    <h2 className="text-3xl font-bold text-white mb-4">Meet Your Revenue Manager</h2>
                    <p className="text-xl text-blue-100 mb-6">
                      Join Connect and get your personal revenue expert, backed by AI-powered tools
                    </p>
                    <div className="flex items-center gap-2 text-blue-100 mb-2">
                      <CheckCircle2 className="h-5 w-5 text-green-400" />
                      <span>Guaranteed Revenue Growth</span>
                    </div>
                    <div className="flex items-center gap-2 text-blue-100 mb-2">
                      <CheckCircle2 className="h-5 w-5 text-green-400" />
                      <span>Global Distribution Network</span>
                    </div>
                    <div className="flex items-center gap-2 text-blue-100">
                      <CheckCircle2 className="h-5 w-5 text-green-400" />
                      <span>Channel Manager & Booking Engine</span>
                    </div>
                  </div>
                  <div className="flex-1 relative">
                    <div className="bg-gradient-to-br from-blue-100 to-blue-50 rounded-lg p-4 h-48 flex items-center justify-center">
                      <img 
                        src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&w=400&q=80" 
                        alt="Professional Revenue Manager"
                        className="rounded-lg shadow-lg h-full w-auto object-cover"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.onerror = null;
                          target.src = 'https://images.unsplash.com/photo-1560250097-0b93528c311a?auto=format&fit=crop&w=400&q=80';
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-8">
                {/* Value Proposition */}
                <div className="grid grid-cols-3 gap-6 mb-8">
                  <div className="text-center p-6 bg-blue-50 rounded-xl">
                    <Users className="h-8 w-8 text-blue-600 mx-auto mb-3" />
                    <h3 className="font-semibold text-gray-900 mb-2">Personal Expert</h3>
                    <p className="text-sm text-gray-600">Dedicated revenue manager working for your success</p>
                  </div>
                  <div className="text-center p-6 bg-green-50 rounded-xl">
                    <Globe className="h-8 w-8 text-green-600 mx-auto mb-3" />
                    <h3 className="font-semibold text-gray-900 mb-2">Global Reach</h3>
                    <p className="text-sm text-gray-600">Access to worldwide distribution channels</p>
                  </div>
                  <div className="text-center p-6 bg-purple-50 rounded-xl">
                    <Zap className="h-8 w-8 text-purple-600 mx-auto mb-3" />
                    <h3 className="font-semibold text-gray-900 mb-2">AI-Powered</h3>
                    <p className="text-sm text-gray-600">Smart pricing backed by market intelligence</p>
                  </div>
                </div>

                {/* What's Included Section */}
                <div className="mb-8">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Complete Revenue Solution</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {[
                      { icon: <Users className="h-5 w-5 text-blue-500" />, 
                        title: "Dedicated Revenue Manager", 
                        desc: "Your personal expert available 24/7" },
                      { icon: <Globe className="h-5 w-5 text-blue-500" />, 
                        title: "Global Distribution", 
                        desc: "Connect to 400+ booking channels" },
                      { icon: <BarChart3 className="h-5 w-5 text-blue-500" />, 
                        title: "Channel Manager", 
                        desc: "Effortless inventory management" },
                      { icon: <ShoppingCart className="h-5 w-5 text-blue-500" />, 
                        title: "Booking Engine", 
                        desc: "Commission-free direct bookings" },
                      { icon: <TrendingUp className="h-5 w-5 text-green-500" />, 
                        title: "Revenue Guarantee", 
                        desc: "Minimum 15% growth or money back" },
                      { icon: <Brain className="h-5 w-5 text-purple-500" />, 
                        title: "AI Market Intelligence", 
                        desc: "Data-driven pricing decisions" }
                    ].map((feature, index) => (
                      <div key={index} className="flex gap-3 p-4 bg-gray-50 rounded-lg">
                        <div className="flex-shrink-0">{feature.icon}</div>
                        <div>
                          <h4 className="font-medium text-gray-900">{feature.title}</h4>
                          <p className="text-sm text-gray-600">{feature.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Success Stories */}
                <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-6 rounded-xl mb-8">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Success Stories</h3>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="bg-white p-4 rounded-lg">
                      <div className="text-2xl font-bold text-green-600 mb-1">+32%</div>
                      <div className="text-sm text-gray-600">Revenue Growth</div>
                      <div className="text-xs text-gray-500 mt-2">Boutique Hotel, 45 rooms</div>
                    </div>
                    <div className="bg-white p-4 rounded-lg">
                      <div className="text-2xl font-bold text-green-600 mb-1">+45%</div>
                      <div className="text-sm text-gray-600">Direct Bookings</div>
                      <div className="text-xs text-gray-500 mt-2">City Hotel, 120 rooms</div>
                    </div>
                    <div className="bg-white p-4 rounded-lg">
                      <div className="text-2xl font-bold text-green-600 mb-1">+28%</div>
                      <div className="text-sm text-gray-600">ADR Increase</div>
                      <div className="text-xs text-gray-500 mt-2">Resort, 80 rooms</div>
                    </div>
                  </div>
                </div>

                {/* CTA Section */}
                <div className="text-center">
                  <div className="inline-flex items-center gap-2 bg-green-100 text-green-700 px-4 py-2 rounded-full text-sm font-medium mb-4">
                    <Zap className="h-4 w-4" />
                    Limited Time Offer: First Month Free
                  </div>
                  <button className="w-full bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-blue-700 transition-colors mb-3">
                    Start Your Revenue Journey
                  </button>
                  <p className="text-sm text-gray-500">
                    15% Revenue Growth Guarantee • 30-Day Free Trial • Cancel Anytime
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ConferenceOpportunityCard = ({ hotel }: { hotel: Hotel | null }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="bg-gradient-to-br from-emerald-500 to-emerald-600 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-5">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
              <Briefcase className="h-5 w-5 text-white" />
            </div>
            <h3 className="font-semibold text-white">Unlock MICE Revenue</h3>
          </div>
          <span className="text-xs px-2 py-1 bg-white/10 rounded-full backdrop-blur-sm text-white">High Growth</span>
        </div>

        <p className="text-sm text-emerald-50 mb-4">
          Tap into the $800B MICE market. Connect with corporate clients and event planners directly.
        </p>

        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-sm text-emerald-50">
            <Calendar className="h-4 w-4" />
            <span>Direct corporate bookings</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-emerald-50">
            <Users className="h-4 w-4" />
            <span>Event planner network</span>
          </div>
        </div>

        <button 
          onClick={() => setShowModal(true)}
          className="w-full py-2 bg-white text-emerald-600 rounded-lg font-medium text-sm hover:bg-emerald-50 transition-colors"
        >
          Maximize MICE Revenue
        </button>
      </div>

      {/* Conference.Place Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="relative">
              {/* Header with gradient background */}
              <div className="bg-gradient-to-r from-emerald-600 to-emerald-700 rounded-t-xl p-6 text-white">
                <button 
                  onClick={() => setShowModal(false)}
                  className="absolute top-4 right-4 text-white/80 hover:text-white"
                >
                  <X className="h-6 w-6" />
                </button>
                <h2 className="text-2xl font-bold mb-2">Transform Your MICE Revenue</h2>
                <p className="text-emerald-100">Join the fastest-growing corporate booking network</p>
              </div>

              <div className="p-6">
                {/* Market Opportunity */}
                <div className="bg-emerald-50 rounded-lg p-4 mb-6">
                  <h3 className="text-lg font-semibold text-emerald-800 mb-2">Massive Market Opportunity</h3>
                  <div className="grid grid-cols-3 gap-4 text-center">
                    <div>
                      <div className="text-2xl font-bold text-emerald-600">$800B</div>
                      <div className="text-sm text-emerald-700">Global MICE Market</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold text-emerald-600">35%</div>
                      <div className="text-sm text-emerald-700">YoY Growth</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold text-emerald-600">2.5x</div>
                      <div className="text-sm text-emerald-700">Higher ADR</div>
                    </div>
                  </div>
                </div>

                {/* Key Benefits */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Revenue Opportunities</h3>
                  <div className="space-y-4">
                    {[
                      { title: "Corporate Direct Bookings", desc: "Connect directly with business travelers and corporate clients" },
                      { title: "Event Management Tools", desc: "Streamlined group booking and event planning capabilities" },
                      { title: "Global Distribution", desc: "Access to worldwide corporate travel managers and event planners" },
                      { title: "Revenue Optimization", desc: "Maximize group rates and corporate contracts" }
                    ].map((benefit, index) => (
                      <div key={index} className="flex items-start gap-3 bg-gray-50 p-4 rounded-lg">
                        <div className="p-2 bg-emerald-100 rounded-full">
                          <Check className="h-4 w-4 text-emerald-600" />
                        </div>
                        <div>
                          <h4 className="font-medium text-gray-900">{benefit.title}</h4>
                          <p className="text-sm text-gray-600">{benefit.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Success Story */}
                <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-4 rounded-lg mb-6">
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-white rounded-lg">
                      <Award className="h-5 w-5 text-yellow-500" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">Success Story</h3>
                      <p className="text-sm text-gray-600">
                        "Our MICE revenue increased by 150% within 6 months of joining Conference.Place. The direct booking capabilities and corporate network are game-changing."
                      </p>
                      <p className="text-sm font-medium text-gray-900 mt-2">
                        - General Manager, Luxury Hotel Chain
                      </p>
                    </div>
                  </div>
                </div>

                {/* CTA Section */}
                <div className="text-center">
                  <button className="bg-emerald-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-emerald-700 transition-colors w-full mb-3">
                    Start Maximizing MICE Revenue
                  </button>
                  <p className="text-sm text-gray-500">Limited time offer: First 3 months at 50% off</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Add new SalesReviewBanner component
const SalesReviewBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    preferredDate: '',
    preferredTime: ''
  });

  return (
    <>
      <div className="bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl p-4 mb-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-white/10 rounded-lg backdrop-blur-sm">
              <TrendingUp className="h-6 w-6 text-white" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-white">Free Online Sales Review</h2>
              <p className="text-purple-100">Discover your property's untapped revenue potential</p>
            </div>
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="px-4 py-2 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors"
          >
            Book Free Consultation
          </button>
        </div>
      </div>

      {/* Consultation Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl max-w-2xl w-full">
            <div className="p-6 border-b border-gray-100">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Book Your Free Sales Review</h2>
                <button 
                  onClick={() => setShowModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4 mb-6">
                <div className="flex items-center gap-3 text-sm text-gray-600">
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Comprehensive revenue performance analysis</span>
                </div>
                <div className="flex items-center gap-3 text-sm text-gray-600">
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Market positioning review</span>
                </div>
                <div className="flex items-center gap-3 text-sm text-gray-600">
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Pricing strategy recommendations</span>
                </div>
              </div>

              <form className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg"
                      value={formData.name}
                      onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                    <input
                      type="email"
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg"
                      value={formData.email}
                      onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                  <input
                    type="tel"
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg"
                    value={formData.phone}
                    onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Preferred Date</label>
                    <input
                      type="date"
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg"
                      value={formData.preferredDate}
                      onChange={(e) => setFormData(prev => ({ ...prev, preferredDate: e.target.value }))}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Preferred Time</label>
                    <select
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg"
                      value={formData.preferredTime}
                      onChange={(e) => setFormData(prev => ({ ...prev, preferredTime: e.target.value }))}
                    >
                      <option value="">Select time</option>
                      <option value="9:00">9:00 AM</option>
                      <option value="10:00">10:00 AM</option>
                      <option value="11:00">11:00 AM</option>
                      <option value="14:00">2:00 PM</option>
                      <option value="15:00">3:00 PM</option>
                      <option value="16:00">4:00 PM</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 bg-purple-600 text-white rounded-lg font-medium hover:bg-purple-700 transition-colors"
                >
                  Schedule Free Consultation
                </button>
              </form>

              <p className="text-sm text-gray-500 text-center mt-4">
                Our revenue specialist will contact you to confirm the appointment
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Overview = () => {
  const { hotel } = useHotel();
  
  const fetchRankingsData = useCallback(async () => {
    if (!hotel?.id) return [] as HotelRanking[];
    return fetchHotelRankings(hotel.id);
  }, [hotel?.id]);

  const fetchTrendsData = useCallback(async () => {
    if (!hotel?.id) return [] as ReviewTrend[];
    return fetchReviewTrends(hotel.id);
  }, [hotel?.id]);

  const { data: rankingsData = [] } = useData<HotelRanking[]>(fetchRankingsData);
  const { data: trendsData = [] } = useData<ReviewTrend[]>(fetchTrendsData);

  return (
    <div className="h-full flex flex-col p-6 space-y-6 overflow-auto bg-gray-50/50">
      <div className="flex-none">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-gray-800">
            {hotel?.name} Overview
          </h1>
          <div className="flex items-center gap-2 text-sm bg-white px-3 py-1.5 rounded-lg border border-gray-100">
            <Clock className="h-4 w-4 text-gray-400" />
            <span className="text-gray-500">Last updated:</span>
            <span className="font-medium text-gray-900">
              {new Date().toLocaleTimeString()}
            </span>
          </div>
        </div>

        <SalesReviewBanner />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <MarketInsightCard hotel={hotel} />
          <ReviewsCard hotel={hotel} />
          <RevenueOpportunityCard hotel={hotel} />
          <ConferenceOpportunityCard hotel={hotel} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
