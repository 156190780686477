import api from '../api';

interface HotelRanking {
  hotel_id: number;
  rank_by_score: number;
  total_hotels: number;
}

interface ReviewTrend {
  date: string;
  average_score: number;
  count: number;
}

export const fetchHotelRankings = async (hotelId: number): Promise<HotelRanking[]> => {
  console.log('Fetching hotel rankings for:', hotelId);
  try {
    const response = await api.get(`/api/hotels/${hotelId}/rankings`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rankings:', error);
    throw error;
  }
};

export const fetchReviewTrends = async (hotelId: number): Promise<ReviewTrend[]> => {
  console.log('Fetching review trends for:', hotelId);
  try {
    const response = await api.get(`/api/hotels/${hotelId}/review-trends`);
    return response.data;
  } catch (error) {
    console.error('Error fetching review trends:', error);
    throw error;
  }
}; 