import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useHotel } from '../../contexts/HotelContext';
import { Search, Plus } from 'lucide-react';
import { searchHotels } from '../../services/hotelApi';

interface Hotel {
  id: number;
  name: string;
  is_default: number;
  location?: string;
}

const HotelSelector = () => {
  const navigate = useNavigate();
  const { hotelId, setHotelId } = useHotel();
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [searchResults, setSearchResults] = useState<Hotel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchHotels = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/user/hotels');
      
      console.log('API Response:', {
        data: response.data,
        isArray: Array.isArray(response.data),
        firstItem: response.data?.[0]
      });
      
      if (response.data && Array.isArray(response.data)) {
        const validHotels = response.data.filter(h => h && typeof h === 'object' && 'id' in h);
        console.log('Valid hotels:', validHotels);
        setHotels(validHotels);
        
        if (!hotelId && validHotels.length > 0) {
          const defaultHotel = validHotels.filter(h => h.is_default)[0] || validHotels[0];
          console.log('Selected hotel:', defaultHotel);
          if (defaultHotel) {
            setHotelId(defaultHotel.id);
            navigate(`/dashboard/${defaultHotel.id}`);
          }
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Failed to fetch hotels:', err);
      setError('Failed to load hotels');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadHotels = async () => {
      try {
        const currentHotelId = window.location.pathname.split('/dashboard/')[1];
        const response = await api.get('/api/user/hotels');
        
        if (response.data && Array.isArray(response.data)) {
          if (response.data.length === 0) {
            setError('No hotels assigned to your account');
            setHotels([]);
            return;
          }
          
          setHotels(response.data);
          
          if (currentHotelId && !hotelId) {
            setHotelId(Number(currentHotelId));
          } else if (!hotelId && response.data.length > 0) {
            const defaultHotels = response.data.filter((h: Hotel) => h.is_default === 1);
            const selectedHotel = defaultHotels.length > 0 ? defaultHotels[0] : response.data[0];
            setHotelId(selectedHotel.id);
            navigate(`/dashboard/${selectedHotel.id}`);
          }
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error('Failed to fetch hotels:', err);
        setError('Failed to load hotels');
        setHotels([]);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    loadHotels();
  }, []);

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (term.length >= 2) {
      try {
        const results = await searchHotels(term);
        setSearchResults(Array.isArray(results) ? results.map(hotel => ({
          id: Number(hotel.id),
          name: hotel.name,
          location: hotel.location,
          is_default: 0
        })) : []);
      } catch (error) {
        console.error('Search error:', error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const addHotelToUser = async (hotelId: number) => {
    try {
      await api.post('/api/user/hotels', { hotelId });
      await fetchHotels();
      setIsSearching(false);
      setSearchTerm('');
    } catch (error) {
      console.error('Error adding hotel:', error);
    }
  };

  const handleHotelChange = (value: string) => {
    if (value === 'add') {
      setIsSearching(true);
    } else {
      const selectedHotels = hotels.filter(h => h.id === Number(value));
      const selectedHotel = selectedHotels[0];
      if (selectedHotel) {
        setHotelId(Number(selectedHotel.id));
        navigate(`/dashboard/${value}`);
      }
    }
  };

  if (loading) {
    return <div>Loading hotels...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="relative">
      {!isSearching ? (
        <select
          value={hotelId || ''}
          onChange={(e) => handleHotelChange(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          disabled={loading}
        >
          {loading ? (
            <option value="">Loading hotels...</option>
          ) : (
            <>
              {hotels.map((hotel) => (
                <option key={hotel.id} value={hotel.id}>
                  {hotel.name}
                </option>
              ))}
              <option value="add">+ Add Hotel</option>
            </>
          )}
        </select>
      ) : (
        <div>
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search hotels..."
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm pr-10"
              autoFocus
            />
            <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
            <div className="max-h-60 overflow-auto rounded-md py-1 text-base">
              {searchResults.length > 0 ? (
                searchResults.map((hotel) => (
                  <div
                    key={`search-${hotel.id}`}
                    onClick={() => addHotelToUser(hotel.id)}
                    className="flex items-center justify-between cursor-pointer px-4 py-2 hover:bg-gray-100"
                  >
                    <div>
                      <div className="font-medium">{hotel.name}</div>
                      {hotel.location && (
                        <div className="text-sm text-gray-500">{hotel.location}</div>
                      )}
                    </div>
                    <Plus className="h-5 w-5 text-primary" />
                  </div>
                ))
              ) : searchTerm.length >= 2 ? (
                <div className="px-4 py-2 text-sm text-gray-500">No hotels found</div>
              ) : (
                <div className="px-4 py-2 text-sm text-gray-500">Type to search...</div>
              )}
              
              <div 
                className="border-t px-4 py-2 text-sm text-gray-500 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  setIsSearching(false);
                  setSearchTerm('');
                }}
              >
                ← Back to hotel list
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelSelector; 